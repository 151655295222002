import React, { useMemo, useState, useEffect } from "react";
import { Text } from 'react-native';
import { Link, Outlet, useParams} from 'react-router-dom';
import { MatchNavbar } from '../Navbar';
import styled from "styled-components";
import { PieChart, PieArcSeries } from 'reaviz';
import { Table2, Table3, Table23 } from "../components/Table";
import MultiRangeSlider from "multi-range-slider-react";

//let localhost_var = "http://localhost:5000"
let localhost_var = "https://api.kagbunnie.com"

function createArray(length) {
    var arr = new Array(length || 0),
        i = length;

    if (arguments.length > 1) {
        var args = Array.prototype.slice.call(arguments, 1);
        while(i--) arr[length-1 - i] = createArray.apply(this, args);
    }

    return arr;
}

function timeConverter(UNIX_timestamp){
	var a = new Date(UNIX_timestamp * 1000);
	var s = a.toLocaleTimeString("en-GB")
	var d = a.toLocaleDateString("en-GB")
	return d + ' ' + s;
  }

function matchLength(delta){
	// calculate (and subtract) whole days
	var days = Math.floor(delta / 86400);
	delta -= days * 86400;
	// calculate (and subtract) whole hours
	var hours = Math.floor(delta / 3600) % 24;
	delta -= hours * 3600;
	// calculate (and subtract) whole minutes
	var minutes = Math.floor(delta / 60) % 60;
	delta -= minutes * 60;
	// what's left is seconds
	var seconds = delta % 60;  // in theory the modulus is not required
	let lengthstring = "";
	if (hours === 1)
	{
		lengthstring += (hours + "h ");
	}
	if (hours !== 1)
	{
		lengthstring += (hours + "h ");
	}

	if (minutes === 1)
	{
		lengthstring += (minutes + "m ");
	}
	if (minutes !== 1)
	{
		lengthstring += (minutes + "m ");
	}

	if (seconds === 1)
	{
		lengthstring += (seconds + "s ");
	}
	if (seconds !== 1)
	{
		lengthstring += (seconds + "s ");
	}
	return lengthstring;
}

function gametimeToMinutes(gametime, cock=false){
	let hours = parseInt(gametime / (30 * 60 * 60));
	let minutes = parseInt((gametime - hours * 30 * 60 * 60) / (30 * 60));
	let seconds = parseInt((gametime - hours * 30 * 60 * 60 - minutes * 30 * 60) / (30));

	let hours_string = hours.toString();
	let minutes_string = minutes.toString();
	let seconds_string = seconds.toString();

	if(hours_string.length === 0)
	{
		hours_string = "0";
	}

	if(minutes_string.length === 0)
	{
		minutes_string = "00";
	}
	else if(minutes_string.length === 1)
	{
		minutes_string = "0" + minutes_string;
	}

	if(seconds_string.length === 0)
	{
		seconds_string = "00";
	}
	else if(seconds_string.length === 1)
	{
		seconds_string = "0" + seconds_string;
	}

	if(!cock)
	return (hours_string + "h " + minutes_string + "m");

	else 
	return (hours_string + "h " + minutes_string + "m " + seconds_string + "s");
}

function getTimeFromGametime(gametime){
	let hours = parseInt(gametime / (30 * 60 * 60));
	let minutes = parseInt((gametime - hours * 30 * 60 * 60) / (30 * 60));
	let seconds = parseInt((gametime - hours * 30 * 60 * 60 - minutes * 30 * 60) / (30));

	let hours_string = hours.toString();
	let minutes_string = minutes.toString();
	let seconds_string = seconds.toString();

	if(hours_string.length === 0)
	{
		hours_string = "00";
	}
	else if(hours_string.length === 1)
	{
		hours_string = "0" + hours_string;
	}

	if(minutes_string.length === 0)
	{
		minutes_string = "00";
	}
	else if(minutes_string.length === 1)
	{
		minutes_string = "0" + minutes_string;
	}

	if(seconds_string.length === 0)
	{
		seconds_string = "00";
	}
	else if(seconds_string.length === 1)
	{
		seconds_string = "0" + seconds_string;
	}

	return ("[" + hours_string + ":" + minutes_string + ":" + seconds_string + "]");
}

function getItemBoughtTeam(buyer, time, leavedictname)
{
	for(let step=0; step<leavedictname.length; ++step)
	{
		if(time > leavedictname[step].time && time < leavedictname[step + 1])
		{
			return leavedictname[step + 1].team
		}
	}
}

function getTeam(number){
	if(number === "0") return "Blue";
	else return "Red";
}

export const EventHistory = () => {
	const { userId } = useParams();
	const matchInfo = GetMatch(userId);

	let history = [];
	let blueplayers = [];
	let redplayers = [];

	// button for showing item bought
	const [showBoughts, setBoughts] = useState(true);
	const toggleBoughts = () => {
		// 👇️ passed function to setState
		setBoughts(current => !current);
	};

	// button for showing kills
	const [showKills, setKills] = useState(true);
	const toggleKills = () => {
		// 👇️ passed function to setState
		setKills(current => !current);
	};

	// button for showing team joining & leaving
	const [showJoins, setJoins] = useState(true);
	const toggleJoins = () => {
		// 👇️ passed function to setState
		setJoins(current => !current);
	};

	if(matchInfo.length === 0) 
	{
		return (
			<>
			<Text>Please wait a few seconds for the match to load...</Text>
			</>
		)
	}

	let matchstarttime = matchInfo[0].gametime;
	let matchendtime = matchInfo[matchInfo.length - 1].gametime;
	let last_time = matchInfo[0].gametime;

	let added_thing = false;
	let buildtime = false;

	for(let step=0; step<matchInfo.length; ++step)
	{
		if(matchInfo[step].gametime > 30 * 60 * 3 && !buildtime)
		{
			buildtime = true;
			history.push(
				<Text style={{marginRight:'auto', fontSize: 16, color: "gray", fontFamily: "Courier"}}>
					{getTimeFromGametime(30 * 60 * 3)}
					<Text style={{marginRight:'auto', fontSize: 16, color: "black", fontWeight: 'bold', fontFamily: "Arial"}}>
						{" Build time ended." }
					</Text>
				</Text>);
		}
		if(matchInfo[step].action_type === "itembought" && matchInfo[step].item !== "filled_bucket")
		{
			last_time = matchInfo[step].gametime;
		}
		if(matchInfo[step].action_type === "matchbegin")
		{
			history.push(
				<Text style={{marginRight:'auto', fontSize: 16, color: "gray", fontFamily: "Courier"}}>
					{getTimeFromGametime(matchInfo[step].gametime)}
					<Text style={{marginRight:'auto', fontSize: 16, color: "black", fontWeight: 'bold', fontFamily: "Arial"}}>
						{" Stats collection started." }
					</Text>
				</Text>);
			continue;
		}
		if(matchInfo[step].action_type === "jointeam" && matchInfo[step].gametime === matchstarttime)
		{
			if (matchInfo[step].team === "0") blueplayers.push(matchInfo[step].joiner);
			if (matchInfo[step].team === "1") redplayers.push(matchInfo[step].joiner);
			continue;
		}

		if(added_thing === false)
		{
			added_thing = true;	
			let blue_composition = "";
			for(let step=0; step<blueplayers.length; ++step)
			{
				blue_composition += blueplayers[step] + ", ";
			}
			let red_composition = "";
			for(let step=0; step<redplayers.length; ++step)
			{
				red_composition += redplayers[step] + ", ";
			}
			history.push(
				<Text style={{marginRight:'auto', fontSize: 16, color: "gray", fontFamily: "Courier"}}>
					{getTimeFromGametime(matchInfo[step - 1].gametime)}
					<Text style={{marginRight:'auto', fontSize: 16, color: "#195E9D", fontFamily: "Arial"}}>
						{" Blue team composition: "} 
						<Text style={{marginRight:'auto', fontSize: 16, color: "black", fontFamily: "Arial"}}>
							{blue_composition.slice(0, -2)}
						</Text>
					</Text>
				</Text>);
			history.push(
				<Text style={{marginRight:'auto', fontSize: 16, color: "gray", fontFamily: "Courier"}}>
					{getTimeFromGametime(matchInfo[step - 1].gametime)}
					<Text style={{marginRight:'auto', fontSize: 16, color: "#C02424", fontFamily: "Arial"}}>
						{" Red team composition: "} 
						<Text style={{marginRight:'auto', fontSize: 16, color: "black", fontFamily: "Arial"}}>
							{red_composition.slice(0, -2)}
						</Text>
					</Text>
				</Text>);
		}

		if(matchInfo[step].action_type === "matchend")
		{
			let team = matchInfo[step].team_won === "0" ? "Blue" : "Red";
			let color = matchInfo[step].team_won === "0" ? "#195E9D" : "#C02424";
			history.push(
			<Text style={{marginRight:'auto', fontSize: 16, color: "black", fontWeight: 'bold', fontFamily: "Arial"}}>
				<Text style={{marginRight:'auto', fontSize: 16, color: "gray", fontWeight: 'normal', fontFamily: "Courier"}}>
					{getTimeFromGametime(matchInfo[step].gametime)}
				</Text>
				{" Match ended. Winning team: " }
				<Text style={{marginRight:'auto', fontSize: 16, color: color, fontFamily: "Arial"}}>
						{team + "."}
				</Text>
			</Text>);
			matchendtime = matchInfo[step].gametime;
			continue;
		}
		if(matchInfo[step].action_type === "jointeam" && matchInfo[step].gametime !== matchstarttime && showJoins)
		{
			let team = matchInfo[step].team === "0" ? "Blue" : "Red";
			let color = matchInfo[step].team === "0" ? "#195E9D" : "#C02424";
			if (matchInfo[step].team === "0") blueplayers.push(matchInfo[step].joiner);
			if (matchInfo[step].team === "1") redplayers.push(matchInfo[step].joiner);
			history.push(
			<Text style={{marginRight:'auto', fontSize: 16, color: "gray", fontFamily: "Courier"}}>
				{getTimeFromGametime(matchInfo[step].gametime)}
				<Text style={{marginRight:'auto', fontSize: 16, color: "black", fontFamily: "Arial"}}>
					{" " + matchInfo[step].joiner + " has joined "}
					<Text style={{marginRight:'auto', fontSize: 16, color: color, fontFamily: "Arial"}}>
						{team + " team."}
					</Text>
				</Text>
			</Text>);
		}
		else if(matchInfo[step].action_type === "leaveteam" && matchInfo[step].gametime !== matchendtime && showJoins)
		{
			let team = matchInfo[step].team === "0" ? "Blue" : "Red";
			let color = matchInfo[step].team === "0" ? "#195E9D" : "#C02424";
			if (matchInfo[step].team === "0") 
			{
				let index = blueplayers.indexOf(matchInfo[step].leaver);
				if (index !== -1) 
				{
					blueplayers.splice(index, 1);
				}
			}
			if (matchInfo[step].team === "1") 
			{
				let index = redplayers.indexOf(matchInfo[step].leaver);
				if (index !== -1) 
				{
					redplayers.splice(index, 1);
				}
			}
			history.push(
				<Text style={{marginRight:'auto', fontSize: 16, color: "gray", fontFamily: "Courier"}}>
					{getTimeFromGametime(matchInfo[step].gametime)}
					<Text style={{marginRight:'auto', fontSize: 16, color: "black", fontFamily: "Arial"}}>
						{" " + matchInfo[step].leaver + " has left "}
						<Text style={{marginRight:'auto', fontSize: 16, color: color, fontFamily: "Arial"}}>
							{team + " team."}
						</Text>
					</Text>
				</Text>);
		}
		else if(matchInfo[step].action_type === "leaveteam" && matchInfo[step].gametime !== matchendtime && showJoins)
		{
			let team = matchInfo[step].team === "0" ? "Blue" : "Red";
			let color = matchInfo[step].team === "0" ? "#195E9D" : "#C02424";
			if (matchInfo[step].team === "0") 
			{
				let index = blueplayers.indexOf(matchInfo[step].leaver);
				if (index !== -1) 
				{
					blueplayers.splice(index, 1);
				}
			}
			if (matchInfo[step].team === "1") 
			{
				let index = redplayers.indexOf(matchInfo[step].leaver);
				if (index !== -1) 
				{
					redplayers.splice(index, 1);
				}
			}
			history.push(
				<Text style={{marginRight:'auto', fontSize: 16, color: "gray", fontFamily: "Courier"}}>
					{getTimeFromGametime(matchInfo[step].gametime)}
					<Text style={{marginRight:'auto', fontSize: 16, color: "black", fontFamily: "Arial"}}>
						{" " + matchInfo[step].leaver + " has left "}
						<Text style={{marginRight:'auto', fontSize: 16, color: color, fontFamily: "Arial"}}>
							{team + " team."}
						</Text>
					</Text>
				</Text>);
		}
		if(matchInfo[step].action_type === "itembought" && matchInfo[step].shop !== "Building in Construction" && matchInfo[step].item !== "filled_bucket" && showBoughts)
		{
			let buyer = matchInfo[step].buyer
			var index = redplayers.indexOf(buyer);
			let color;
			if (index !== -1) 
			{
				color = "#C02424";
			}
			else
			{
				color = "#195E9D";
			}
			history.push(
			<Text style={{marginRight:'auto', fontSize: 16, color: "gray", fontFamily: "Courier", fontWeight: 'normal'}}>
				{getTimeFromGametime(matchInfo[step].gametime)}
				<Text style={{marginRight:'auto', fontSize: 16, color: color, fontFamily: "Arial", fontWeight: 'bold'}}>
					{" " + matchInfo[step].buyer}
					<Text style={{marginRight:'auto', fontSize: 16, color: "black", fontFamily: "Arial", fontWeight: 'normal'}}>
						{" has bought " + matchInfo[step].item + " from " + matchInfo[step].shop}
					</Text>
				</Text>
			</Text>);
		}
		if(matchInfo[step].action_type === "itembought" && matchInfo[step].item === "filled_bucket" && matchInfo[step].gametime === "Shop" && showBoughts)
		{
			let buyer = matchInfo[step].buyer
			let index = redplayers.indexOf(buyer);
			let color;
			if (index !== -1) 
			{
				color = "#C02424";
			}
			else
			{
				color = "#195E9D";
			}
			let shop;
			if (matchInfo[step].shop === "Builder") shop = "Builder Shop"
			if (matchInfo[step].shop === "Mid") shop = "Mid Shop"
			history.push(
			<Text style={{marginRight:'auto', fontSize: 16, color: "gray", fontFamily: "Courier", fontWeight: 'normal'}}>
				{getTimeFromGametime(last_time)}
				<Text style={{marginRight:'auto', fontSize: 16, color: color, fontFamily: "Arial", fontWeight: 'bold'}}>
					{" " + matchInfo[step].buyer}
					<Text style={{marginRight:'auto', fontSize: 16, color: "black", fontFamily: "Arial", fontWeight: 'normal'}}>
						{" has bought Water Bucket from " + shop}
					</Text>
				</Text>
			</Text>);
		}
		if(matchInfo[step].action_type === "kill" && showKills)
		{
			let color1;
			if(matchInfo[step].killer_team === "0") color1 = "#195E9D";
			else color1 = "#C02424";

			let color2;
			if(matchInfo[step].victim_team === "0") color2 = "#195E9D";
			else color2 = "#C02424";

			let hitter = matchInfo[step].hitter;

			if(hitter === "builder") hitter = "pickaxe"
			if(hitter === "fall") hitter = "fall damage"

			if(matchInfo[step].killer === matchInfo[step].victim)
			{
				history.push(
					<Text style={{marginRight:'auto', fontSize: 16, color: "gray", fontFamily: "Courier", fontWeight: 'normal'}}>
						{getTimeFromGametime(matchInfo[step].gametime)}
						<Text style={{marginRight:'auto', fontSize: 16, color: color1, fontFamily: "Arial", fontWeight: 'bold'}}>
							{" " + matchInfo[step].killer}
							<Text style={{marginRight:'auto', fontSize: 16, color: "black", fontFamily: "Arial", fontWeight: 'normal'}}>
								{" (" + matchInfo[step].killer_class + ") commited suicide"}
									<Text style={{marginRight:'auto', fontSize: 16, color: "black", fontFamily: "Arial", fontWeight: 'normal'}}>
									{" with " + hitter}
									</Text>
							</Text>
						</Text>
					</Text>);
			}
			else
			{
				history.push(
				<Text style={{marginRight:'auto', fontSize: 16, color: "gray", fontFamily: "Courier", fontWeight: 'normal'}}>
					{getTimeFromGametime(matchInfo[step].gametime)}
					<Text style={{marginRight:'auto', fontSize: 16, color: color1, fontFamily: "Arial", fontWeight: 'bold'}}>
						{" " + matchInfo[step].killer}
						<Text style={{marginRight:'auto', fontSize: 16, color: "black", fontFamily: "Arial", fontWeight: 'normal'}}>
							{" (" + matchInfo[step].killer_class + ") has killed"}
							<Text style={{marginRight:'auto', fontSize: 16, color: color2, fontFamily: "Arial", fontWeight: 'bold'}}>
								{" " + matchInfo[step].victim}
								<Text style={{marginRight:'auto', fontSize: 16, color: "black", fontFamily: "Arial", fontWeight: 'normal'}}>
								{" (" + matchInfo[step].victim_class + ") with " + hitter}
								</Text>
							</Text>
						</Text>
					</Text>
				</Text>);
			}
		}
	}

	const Button = styled.button`
	background-color: #007BFF;
	color: #333;
	font-size: 1.5rem;
	padding: 3px 30px;
	border-radius: 5px;
	margin: 10px 0px;
	cursor: pointer;
	width: 100%;
	`;

	const ButtonGroup = styled.div`
	display: flex;
	gap: 3px;
	flex-direction: row;
	text-align: center;
	justify-content: center;
	`

	return (
	<>
	<ButtonGroup>
      	<Button onClick={toggleBoughts}><Text style={{fontSize: 16, color: "white"}}>{showBoughts ? "Hide item purchases" : "Show item purchases"}</Text></Button>
      	<Button onClick={toggleKills}><Text style={{fontSize: 16, color: "white"}}>{showKills ? "Hide kills" : "Show kills"}</Text></Button>
      	<Button onClick={toggleJoins}><Text style={{fontSize: 16, color: "white"}}>{showJoins ? "Hide team join & leave messages" : "Show team join & leave messages"}</Text></Button>
	</ButtonGroup>
		{ history.map((component) => (
		<div className="historytest">
			{ component }
		</div>))
		}
	  </>
	);
};

class Player 
{
  constructor(name) 
  {
    this.m_username = name;

    this.m_kills = 0;
    this.m_deaths = 0;
    this.m_kdr = 0;

    this.m_k_kills = 0;
    this.m_k_deaths = 0; 
    this.m_k_kdr = 0;

    this.m_b_kills = 0;
    this.m_b_deaths = 0;
    this.m_b_kdr = 0;

    this.m_a_kills = 0;
    this.m_a_deaths = 0;
    this.m_a_kdr = 0;

	this.m_team = 0;
	this.m_time_played = 0;
	this.m_teamkills = 0;
	this.m_suicides = 0;
	this.m_multikills = [];
	this.m_killhitters = {};
	this.m_deathhitters = {};
	this.m_itemsbought = {};
  }
}

let MAX1;

export const Scoreboard = () => {
	const { userId } = useParams();
	const matchInfo = GetMatch(userId);

	// button
	const [showTogether, setTogether] = useState(true);
	const toggleTogether = () => {
		// 👇️ passed function to setState
		setTogether(current => !current);
	};

	const [showKDL, setShowKDL] = useState(true);
	const toggleShowKDL = () => {
		// 👇️ passed function to setState
		setShowKDL(current => !current);
	};

	const [showSTKL, setShowSTKL] = useState(true);
	const toggleShowSTKL = () => {
		// 👇️ passed function to setState
		setShowSTKL(current => !current);
	};

	const [showHT, setShowHT] = useState(true);
	const toggleShowHT = () => {
		// 👇️ passed function to setState
		setShowHT(current => !current);
	};

	const [showIB, setShowIB] = useState(true);
	const toggleShowIB = () => {
		// 👇️ passed function to setState
		setShowIB(current => !current);
	};

	const [minValue, set_minValue] = useState(0);
	const [maxValue, set_maxValue] = useState(1.5);
	const handleInput = (e) => {
		set_minValue(e.minValue);
		set_maxValue(e.maxValue);
	};

	let blueplayers = [];
	let redplayers = [];
	let allplayers = [];

	for (let step=0; step<matchInfo.length; ++step)
	{
		if(matchInfo[step].action_type === "jointeam")
		{
			if(matchInfo[step].team === "0" && blueplayers.indexOf(matchInfo[step].joiner) === -1) blueplayers.push(matchInfo[step].joiner);
			if(matchInfo[step].team === "1" && redplayers.indexOf(matchInfo[step].joiner) === -1) redplayers.push(matchInfo[step].joiner);
			if(allplayers.indexOf(matchInfo[step].joiner) === -1) allplayers.push(matchInfo[step].joiner);
		}
	}

	if(matchInfo.length > 0)
	{
		MAX1 = parseInt(parseInt(matchInfo[matchInfo.length - 1].gametime) / (30 * 60));
		if(maxValue === 1.5)
		{
			set_maxValue(MAX1);
		}
	}

	let bluedata = [];
	let reddata = [];
	let bluereddata = []

	let sus_minValue = (minValue * 30 * 60);
	let sus_maxValue = (maxValue * 30 * 60) + 60 * 30;

	let leavedict = [];

	for (let step=0; step<matchInfo.length; ++step)
	{
		if(matchInfo[step].action_type === "leaveteam" || matchInfo[step].action_type === "jointeam")
		{
			let name = matchInfo[step].action_type === "jointeam" ? matchInfo[step].joiner : matchInfo[step].leaver;

			let time = 0;
			if (matchInfo[step].action_type === "jointeam")
			{
				if(matchInfo[step].gametime >= sus_minValue )
				{
					if(matchInfo[step].gametime <= sus_maxValue)
					{
						time = matchInfo[step].gametime;
					}
					else
					{
						time = sus_maxValue;
					}
				}
				else
				{
					time = sus_minValue;
				}
			}
			else if (matchInfo[step].action_type === "leaveteam")
			{
				if(matchInfo[step].gametime <= sus_maxValue )
				{
					if(matchInfo[step].gametime >= sus_minValue)
					{
						time = matchInfo[step].gametime;
					}
					else
					{
						time = sus_minValue;
					}
				}
				else
				{
					time = sus_maxValue;
				}
			}

			let team = matchInfo[step].team;

			let obj = {};
			obj["type"] = matchInfo[step].action_type === "jointeam" ? "join" : "leave";
			obj["time"] = parseInt(time);
			obj["team"] = team;

			if(leavedict[name])
			{
				leavedict[name].push(obj);
			}
			else
			{
				leavedict[name] = [obj];
			}
		}
	}

	let bluetotal = new Player("TOTAL");
	let redtotal = new Player("TOTAL");
	let blueredtotal = new Player("TOTAL");

	let hitterarray = ["fall", "water", "fire", "stomp", "builder", "spikes", "sword", "shield", "bombarrow", "bomb", "keg", "mine", "arrow", "ballista", "boulder", "stones", "drill", "saw"]
	let itemarray = ["Bomb", "Water Bombs", "Mine", "Keg", 
	"Water Arrows", "Fire Arrows", "Bomb Arrow", 
	"filled_bucket", "Boulder", "Trampoline", "Mill Saw", "Drill", "Crate", "Sponge",
	"Burger"]

	for(let stepd=0; stepd<hitterarray.length; ++stepd)
	{
		bluetotal.m_killhitters[hitterarray[stepd]] = 0;
		bluetotal.m_deathhitters[hitterarray[stepd]] = 0;
		redtotal.m_killhitters[hitterarray[stepd]] = 0;
		redtotal.m_deathhitters[hitterarray[stepd]] = 0;
		blueredtotal.m_killhitters[hitterarray[stepd]] = 0;
		blueredtotal.m_deathhitters[hitterarray[stepd]] = 0;
	}

	for(let stepd=0; stepd<itemarray.length; ++stepd)
	{
		let itemname = itemarray[stepd];
		if (itemname === "filled_bucket") itemname = "Water Bucket"
		bluetotal.m_itemsbought[itemname] = 0;
		redtotal.m_itemsbought[itemname] = 0;
		blueredtotal.m_itemsbought[itemname] = 0;
	}

	for (let step=0; step<blueplayers.length; ++step)
	{
		let name = blueplayers[step];
		let current_player = new Player(name);
		current_player.m_kills = matchInfo.filter(obj => {if (obj.killer === name && obj.killer_team === "0" && obj.victim_team === "1" && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue) {return true;} return false;}).length;
		current_player.m_deaths = matchInfo.filter(obj => {if (obj.victim === name && obj.victim_team === "0" && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue)  {return true;} return false;}).length;
		current_player.m_k_kills = matchInfo.filter(obj => {if (obj.killer === name && obj.killer_class === "knight" && obj.killer_team === "0" && obj.victim_team === "1"  && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue)  {return true;} return false;}).length;
		current_player.m_k_deaths = matchInfo.filter(obj => {if (obj.victim === name && obj.victim_class === "knight" && obj.victim_team === "0" && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue)  {return true;} return false;}).length;
		current_player.m_a_kills = matchInfo.filter(obj => {if (obj.killer === name && obj.killer_class === "archer" && obj.killer_team === "0" && obj.victim_team === "1"  && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue)  {return true;} return false;}).length;
		current_player.m_a_deaths = matchInfo.filter(obj => {if (obj.victim === name && obj.victim_class === "archer" && obj.victim_team === "0" && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue)  {return true;} return false;}).length;
		current_player.m_b_kills = matchInfo.filter(obj => {if (obj.killer === name && obj.killer_class === "builder" && obj.killer_team === "0" && obj.victim_team === "1"  && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue)  {return true;} return false;}).length;
		current_player.m_b_deaths = matchInfo.filter(obj => {if (obj.victim === name && obj.victim_class === "builder" && obj.victim_team === "0" && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue)  {return true;} return false;}).length;
		current_player.m_team = 0;
		current_player.m_teamkills = matchInfo.filter(obj => {if (obj.killer === name && obj.victim !== name && obj.killer_team === "0" && obj.victim_team === "0" && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue) {return true;} return false;}).length;
		current_player.m_suicides = matchInfo.filter(obj => {if (obj.killer === name && obj.victim === name && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue) {return true;} return false;}).length;

		for(let stepd=0; stepd<hitterarray.length; ++stepd)
		{
			current_player.m_killhitters[hitterarray[stepd]] = matchInfo.filter(obj => {if (obj.killer === name && obj.killer_team === "0" && obj.victim_team === "1" && obj.hitter === hitterarray[stepd] && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue) {return true;} return false;}).length;
			current_player.m_deathhitters[hitterarray[stepd]] = matchInfo.filter(obj => {if (obj.victim === name && obj.victim_team === "0" && obj.hitter === hitterarray[stepd] && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue) {return true;} return false;}).length;
			bluetotal.m_killhitters[hitterarray[stepd]] += current_player.m_killhitters[hitterarray[stepd]];
			bluetotal.m_deathhitters[hitterarray[stepd]] += current_player.m_deathhitters[hitterarray[stepd]];
		}
		for(let stepd=0; stepd<itemarray.length; ++stepd)
		{
			// we have to check what team they were in by using the join/leave info because i forgot to store team in itembought actions lmao
			let itemname = itemarray[stepd];
			if (itemname === "filled_bucket") itemname = "Water Bucket"
			current_player.m_itemsbought[itemname] = matchInfo.filter(obj => {if (obj.buyer === name && getItemBoughtTeam(obj.buyer, obj.gametime, leavedict[obj.buyer]) === "0" && obj.item === itemarray[stepd] && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue) {return true;} return false;}).length;
			bluetotal.m_itemsbought[itemname] += current_player.m_itemsbought[itemname];
		}

		for(let stepd=0; stepd<leavedict[name].length; stepd += 2)
		{
			if(leavedict[name][stepd].team !== "0") continue;
			let timedelta = (leavedict[name][stepd+1].time - leavedict[name][stepd].time);
			current_player.m_time_played += timedelta;
		}

		bluetotal.m_kills += current_player.m_kills;
		bluetotal.m_deaths += current_player.m_deaths;
		bluetotal.m_k_kills += current_player.m_k_kills;
		bluetotal.m_k_deaths += current_player.m_k_deaths;
		bluetotal.m_a_kills += current_player.m_a_kills;
		bluetotal.m_a_deaths += current_player.m_a_deaths;
		bluetotal.m_b_kills += current_player.m_b_kills;
		bluetotal.m_b_deaths += current_player.m_b_deaths;
		bluetotal.m_suicides += current_player.m_suicides;
		bluetotal.m_teamkills += current_player.m_teamkills;

		if(current_player.m_kills === 0) continue;
		bluedata.push(current_player);
		bluereddata.push(current_player);
	}
	for (let step=0; step<redplayers.length; ++step)
	{
		let name = redplayers[step];
		let current_player = new Player(name);
		current_player.m_kills = matchInfo.filter(obj => {if (obj.killer === name && obj.killer_team === "1" && obj.victim_team === "0" && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue)  {return true;} return false;}).length;
		current_player.m_deaths = matchInfo.filter(obj => {if (obj.victim === name && obj.victim_team === "1" && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue)  {return true;} return false;}).length;
		current_player.m_k_kills = matchInfo.filter(obj => {if (obj.killer === name && obj.killer_class === "knight" && obj.killer_team === "1" && obj.victim_team === "0" && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue)  {return true;} return false;}).length;
		current_player.m_k_deaths = matchInfo.filter(obj => {if (obj.victim === name && obj.victim_class === "knight" && obj.victim_team === "1" && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue)  {return true;} return false;}).length;
		current_player.m_a_kills = matchInfo.filter(obj => {if (obj.killer === name && obj.killer_class === "archer" && obj.killer_team === "1" && obj.victim_team === "0" && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue)  {return true;} return false;}).length;
		current_player.m_a_deaths = matchInfo.filter(obj => {if (obj.victim === name && obj.victim_class === "archer" && obj.victim_team === "1" && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue)  {return true;} return false;}).length;
		current_player.m_b_kills = matchInfo.filter(obj => {if (obj.killer === name && obj.killer_class === "builder" && obj.killer_team === "1" && obj.victim_team === "0" && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue)  {return true;} return false;}).length;
		current_player.m_b_deaths = matchInfo.filter(obj => {if (obj.victim === name && obj.victim_class === "builder" && obj.victim_team === "1" && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue)  {return true;} return false;}).length;
		current_player.m_team = 1;
		current_player.m_teamkills = matchInfo.filter(obj => {if (obj.killer === name && obj.victim !== name && obj.killer_team === "1" && obj.victim_team === "1" && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue) {return true;} return false;}).length;
		current_player.m_suicides = matchInfo.filter(obj => {if (obj.killer === name && obj.victim === name && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue) {return true;} return false;}).length;

		for(let stepd=0; stepd<hitterarray.length; ++stepd)
		{
			current_player.m_killhitters[hitterarray[stepd]] = matchInfo.filter(obj => {if (obj.killer === name && obj.killer_team === "1" && obj.victim_team === "0" && obj.hitter === hitterarray[stepd] && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue) {return true;} return false;}).length;
			current_player.m_deathhitters[hitterarray[stepd]] = matchInfo.filter(obj => {if (obj.victim === name && obj.victim_team === "1" && obj.hitter === hitterarray[stepd] && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue) {return true;} return false;}).length;
			redtotal.m_killhitters[hitterarray[stepd]] += current_player.m_killhitters[hitterarray[stepd]];
			redtotal.m_deathhitters[hitterarray[stepd]] += current_player.m_deathhitters[hitterarray[stepd]];
		}
		for(let stepd=0; stepd<itemarray.length; ++stepd)
		{
			// we have to check what team they were in by using the join/leave info because i forgot to store team in itembought actions lmao
			let itemname = itemarray[stepd];
			if (itemname === "filled_bucket") itemname = "Water Bucket"
			current_player.m_itemsbought[itemname] = matchInfo.filter(obj => {if (obj.buyer === name && getItemBoughtTeam(obj.buyer, obj.gametime, leavedict[obj.buyer]) === "1" && obj.item === itemarray[stepd] && parseInt(obj.gametime) >= sus_minValue && parseInt(obj.gametime) <= sus_maxValue) {return true;} return false;}).length;
			redtotal.m_itemsbought[itemname] += current_player.m_itemsbought[itemname];
		}

		for(let stepd=0; stepd<leavedict[name].length; stepd += 2)
		{
			if(leavedict[name][stepd].team !== "1") continue;
			let timedelta = (leavedict[name][stepd+1].time - leavedict[name][stepd].time);
			current_player.m_time_played += timedelta;
		}

		redtotal.m_kills += current_player.m_kills;
		redtotal.m_deaths += current_player.m_deaths;
		redtotal.m_k_kills += current_player.m_k_kills;
		redtotal.m_k_deaths += current_player.m_k_deaths;
		redtotal.m_a_kills += current_player.m_a_kills;
		redtotal.m_a_deaths += current_player.m_a_deaths;
		redtotal.m_b_kills += current_player.m_b_kills;
		redtotal.m_b_deaths += current_player.m_b_deaths;
		redtotal.m_suicides += current_player.m_suicides;
		redtotal.m_teamkills += current_player.m_teamkills;

		if(current_player.m_kills === 0) continue;
		reddata.push(current_player);
		bluereddata.push(current_player);
	}

	blueredtotal.m_kills += bluetotal.m_kills;
	blueredtotal.m_deaths += bluetotal.m_deaths;
	blueredtotal.m_k_kills += bluetotal.m_k_kills;
	blueredtotal.m_k_deaths += bluetotal.m_k_deaths;
	blueredtotal.m_a_kills += bluetotal.m_a_kills;
	blueredtotal.m_a_deaths += bluetotal.m_a_deaths;
	blueredtotal.m_b_kills += bluetotal.m_b_kills;
	blueredtotal.m_b_deaths += bluetotal.m_b_deaths;
	blueredtotal.m_suicides += bluetotal.m_suicides;
	blueredtotal.m_teamkills += bluetotal.m_teamkills;
	blueredtotal.m_kills += redtotal.m_kills;
	blueredtotal.m_deaths += redtotal.m_deaths;
	blueredtotal.m_k_kills += redtotal.m_k_kills;
	blueredtotal.m_k_deaths += redtotal.m_k_deaths;
	blueredtotal.m_a_kills += redtotal.m_a_kills;
	blueredtotal.m_a_deaths += redtotal.m_a_deaths;
	blueredtotal.m_b_kills += redtotal.m_b_kills;
	blueredtotal.m_b_deaths += redtotal.m_b_deaths;
	blueredtotal.m_suicides += redtotal.m_suicides;
	blueredtotal.m_teamkills += redtotal.m_teamkills;

	for(let stepd=0; stepd<hitterarray.length; ++stepd)
	{
		blueredtotal.m_killhitters[hitterarray[stepd]] += bluetotal.m_killhitters[hitterarray[stepd]];
		blueredtotal.m_deathhitters[hitterarray[stepd]] += bluetotal.m_deathhitters[hitterarray[stepd]];
		blueredtotal.m_killhitters[hitterarray[stepd]] += redtotal.m_killhitters[hitterarray[stepd]];
		blueredtotal.m_deathhitters[hitterarray[stepd]] += redtotal.m_deathhitters[hitterarray[stepd]];
	}

	for(let stepd=0; stepd<itemarray.length; ++stepd)
	{
		let itemname = itemarray[stepd];
		if (itemname === "filled_bucket") itemname = "Water Bucket"
		blueredtotal.m_itemsbought[itemname] += bluetotal.m_itemsbought[itemname];
		blueredtotal.m_itemsbought[itemname] += redtotal.m_itemsbought[itemname];
	}
	
	const columns = useMemo(
		() => [
		  {
			Header: "Blue Leaderboard",
			Footer: "",
			columns: [
			  {
				Header: '#',
				Footer: "",
				id: 'id',
				Cell: ({ row, flatRows }) => {
					if (row.original.m_username !== "TOTAL")
				  return flatRows.indexOf(row) + 1;
				},
				maxWidth: 50,
				width: 50,
				minWidth: 50,
			  },
			  {
				Header: "Username",
				Footer: "Total:",
				accessor: "m_username",
				maxWidth: 150,
				width: 150,
				minWidth: 150,
			  },
			  {
				Header: "Kills",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_kills + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_kills",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Deaths",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_deaths + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_deaths",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "KDR",
				Footer: info => {
					// Only calculate total visits if rows change
					const totalk = React.useMemo(
					  () =>
						info.rows.reduce((sum, row) => row.values.m_kills + sum, 0),
					  [info.rows]
					)
					const totald = React.useMemo(
						() =>
						  info.rows.reduce((sum, row) => row.values.m_deaths + sum, 0),
						[info.rows]
					  )

					const avgkd = (totalk / totald).toFixed(2)
		
					return <>{avgkd}</>
				  },
				accessor: row => row.m_deaths === 0 ? (row.m_kills / 1).toFixed(2) : (row.m_kills / row.m_deaths).toFixed(2),
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  {
				Header: "Knight Kills",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_k_kills + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_k_kills",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Knight Deaths",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_k_deaths + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_k_deaths",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Knight KDR",
				Footer: info => {
					// Only calculate total visits if rows change
					const totalk = React.useMemo(
					  () =>
						info.rows.reduce((sum, row) => row.values.m_k_kills + sum, 0),
					  [info.rows]
					)
					const totald = React.useMemo(
						() =>
						  info.rows.reduce((sum, row) => row.values.m_k_deaths + sum, 0),
						[info.rows]
					  )

					const avgkd = (totalk / totald).toFixed(2)
		
					return <>{avgkd}</>
				  },
				accessor: row => row.m_k_deaths === 0 ? (row.m_k_kills / 1).toFixed(2) : (row.m_k_kills / row.m_k_deaths).toFixed(2),
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  {
				Header: "Archer Kills",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_a_kills + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_a_kills",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Archer Deaths",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_a_deaths + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_a_deaths",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Archer KDR",
				Footer: info => {
					// Only calculate total visits if rows change
					const totalk = React.useMemo(
					  () =>
						info.rows.reduce((sum, row) => row.values.m_a_kills + sum, 0),
					  [info.rows]
					)
					const totald = React.useMemo(
						() =>
						  info.rows.reduce((sum, row) => row.values.m_a_deaths + sum, 0),
						[info.rows]
					  )

					const avgkd = (totalk / totald).toFixed(2)
		
					return <>{avgkd}</>
				  },
				accessor: row => row.m_a_deaths === 0 ? (row.m_a_kills / 1).toFixed(2) : (row.m_a_kills / row.m_a_deaths).toFixed(2),
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  {
				Header: "Builder Kills",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_b_kills + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_b_kills",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Builder Deaths",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_b_deaths + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_b_deaths",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Builder KDR",
				Footer: info => {
					// Only calculate total visits if rows change
					const totalk = React.useMemo(
					  () =>
						info.rows.reduce((sum, row) => row.values.m_b_kills + sum, 0),
					  [info.rows]
					)
					const totald = React.useMemo(
						() =>
						  info.rows.reduce((sum, row) => row.values.m_b_deaths + sum, 0),
						[info.rows]
					  )

					const avgkd = (totalk / totald).toFixed(2)
		
					return <>{avgkd}</>
				  },
				accessor: row => row.m_b_deaths === 0 ? (row.m_b_kills / 1).toFixed(2) : (row.m_b_kills / row.m_b_deaths).toFixed(2),
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  {
				Header: "Time played",
				accessor: "m_time_played",
				Footer: "",
				Cell: ({ row }) => {
					return gametimeToMinutes(row.original.m_time_played);
				  },
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  {
				Header: "Kills / minute",
				Footer: info => {
					// Only calculate total visits if rows change
					const totalk = React.useMemo(
					  () =>
						info.rows.reduce((sum, row) => row.values.m_kills + sum, 0),
					  [info.rows]
					)

					const avg = (totalk / MAX1).toFixed(2)
		
					return <>{avg}</>
				  },
				accessor: row => (row.m_kills / (row.m_time_played / 1800)).toFixed(2),
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  {
				Header: "Deaths / minute",
				Footer: info => {
					// Only calculate total visits if rows change
					const totalk = React.useMemo(
					  () =>
						info.rows.reduce((sum, row) => row.values.m_deaths + sum, 0),
					  [info.rows]
					)

					const avg = (totalk / MAX1).toFixed(2)
		
					return <>{avg}</>
				  },
				accessor: row => (row.m_deaths / (row.m_time_played / 1800)).toFixed(2),
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  
			]
		  }
		],
		[]
	  );

	  const columns2 = useMemo(
		() => [
		  {
			Header: "Red Leaderboard",
			Footer: "",
			columns: [
			  {
				Header: '#',
				Footer: "",
				id: 'id',
				Cell: ({ row, flatRows }) => {
					if (row.original.m_username !== "TOTAL")
				  return flatRows.indexOf(row) + 1;
				},
				maxWidth: 50,
				width: 50,
				minWidth: 50,
			  },
			  {
				Header: "Username",
				Footer: "Total:",
				accessor: "m_username",
				maxWidth: 150,
				width: 150,
				minWidth: 150,
			  },
			  {
				Header: "Kills",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_kills + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_kills",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Deaths",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_deaths + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_deaths",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "KDR",
				Footer: info => {
					// Only calculate total visits if rows change
					const totalk = React.useMemo(
					  () =>
						info.rows.reduce((sum, row) => row.values.m_kills + sum, 0),
					  [info.rows]
					)
					const totald = React.useMemo(
						() =>
						  info.rows.reduce((sum, row) => row.values.m_deaths + sum, 0),
						[info.rows]
					  )

					const avgkd = (totalk / totald).toFixed(2)
		
					return <>{avgkd}</>
				  },
				accessor: row => row.m_deaths === 0 ? (row.m_kills / 1).toFixed(2) : (row.m_kills / row.m_deaths).toFixed(2),
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  {
				Header: "Knight Kills",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_k_kills + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_k_kills",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Knight Deaths",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_k_deaths + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_k_deaths",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Knight KDR",
				Footer: info => {
					// Only calculate total visits if rows change
					const totalk = React.useMemo(
					  () =>
						info.rows.reduce((sum, row) => row.values.m_k_kills + sum, 0),
					  [info.rows]
					)
					const totald = React.useMemo(
						() =>
						  info.rows.reduce((sum, row) => row.values.m_k_deaths + sum, 0),
						[info.rows]
					  )

					const avgkd = (totalk / totald).toFixed(2)
		
					return <>{avgkd}</>
				  },
				accessor: row => row.m_k_deaths === 0 ? (row.m_k_kills / 1).toFixed(2) : (row.m_k_kills / row.m_k_deaths).toFixed(2),
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  {
				Header: "Archer Kills",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_a_kills + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_a_kills",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Archer Deaths",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_a_deaths + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_a_deaths",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Archer KDR",
				Footer: info => {
					// Only calculate total visits if rows change
					const totalk = React.useMemo(
					  () =>
						info.rows.reduce((sum, row) => row.values.m_a_kills + sum, 0),
					  [info.rows]
					)
					const totald = React.useMemo(
						() =>
						  info.rows.reduce((sum, row) => row.values.m_a_deaths + sum, 0),
						[info.rows]
					  )

					const avgkd = (totalk / totald).toFixed(2)
		
					return <>{avgkd}</>
				  },
				accessor: row => row.m_a_deaths === 0 ? (row.m_a_kills / 1).toFixed(2) : (row.m_a_kills / row.m_a_deaths).toFixed(2),
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  {
				Header: "Builder Kills",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_b_kills + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_b_kills",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Builder Deaths",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_b_deaths + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_b_deaths",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Builder KDR",
				Footer: info => {
					// Only calculate total visits if rows change
					const totalk = React.useMemo(
					  () =>
						info.rows.reduce((sum, row) => row.values.m_b_kills + sum, 0),
					  [info.rows]
					)
					const totald = React.useMemo(
						() =>
						  info.rows.reduce((sum, row) => row.values.m_b_deaths + sum, 0),
						[info.rows]
					  )

					const avgkd = (totalk / totald).toFixed(2)
		
					return <>{avgkd}</>
				  },
				accessor: row => row.m_b_deaths === 0 ? (row.m_b_kills / 1).toFixed(2) : (row.m_b_kills / row.m_b_deaths).toFixed(2),
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  {
				Header: "Time played",
				accessor: "m_time_played",
				Footer: "",
				Cell: ({ row }) => {
					return gametimeToMinutes(row.original.m_time_played);
				  },
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  {
				Header: "Kills / minute",
				Footer: info => {
					// Only calculate total visits if rows change
					const totalk = React.useMemo(
					  () =>
						info.rows.reduce((sum, row) => row.values.m_kills + sum, 0),
					  [info.rows]
					)

					const avg = (totalk / MAX1).toFixed(2)
		
					return <>{avg}</>
				  },
				accessor: row => (row.m_kills / (row.m_time_played / 1800)).toFixed(2),
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  {
				Header: "Deaths / minute",
				Footer: info => {
					// Only calculate total visits if rows change
					const totalk = React.useMemo(
					  () =>
						info.rows.reduce((sum, row) => row.values.m_deaths + sum, 0),
					  [info.rows]
					)

					const avg = (totalk / MAX1).toFixed(2)
		
					return <>{avg}</>
				  },
				accessor: row => (row.m_deaths / (row.m_time_played / 1800)).toFixed(2),
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  
			]
		  }
		],
		[]
	  );

	  const columns3 = useMemo(
		() => [
		  {
			Header: "Match Leaderboard",
			Footer: "",
			columns: [
			  {
				Header: '#',
				Footer: "",
				id: 'id',
				Cell: ({ row, flatRows }) => {
					if (row.original.m_username !== "TOTAL")
				  return flatRows.indexOf(row) + 1;
				},
				maxWidth: 50,
				width: 50,
				minWidth: 50,
			  },
			  {
				Header: "Username",
				Footer: "Total:",
				accessor: "m_username",
				maxWidth: 150,
				width: 150,
				minWidth: 150,
			  },
			  {
				Header: "Kills",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_kills + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_kills",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Deaths",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_deaths + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_deaths",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "KDR",
				Footer: info => {
					// Only calculate total visits if rows change
					const totalk = React.useMemo(
					  () =>
						info.rows.reduce((sum, row) => row.values.m_kills + sum, 0),
					  [info.rows]
					)
					const totald = React.useMemo(
						() =>
						  info.rows.reduce((sum, row) => row.values.m_deaths + sum, 0),
						[info.rows]
					  )

					const avgkd = (totalk / totald).toFixed(2)
		
					return <>{avgkd}</>
				  },
				accessor: row => row.m_deaths === 0 ? (row.m_kills / 1).toFixed(2) : (row.m_kills / row.m_deaths).toFixed(2),
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  {
				Header: "Knight Kills",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_k_kills + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_k_kills",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Knight Deaths",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_k_deaths + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_k_deaths",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Knight KDR",
				Footer: info => {
					// Only calculate total visits if rows change
					const totalk = React.useMemo(
					  () =>
						info.rows.reduce((sum, row) => row.values.m_k_kills + sum, 0),
					  [info.rows]
					)
					const totald = React.useMemo(
						() =>
						  info.rows.reduce((sum, row) => row.values.m_k_deaths + sum, 0),
						[info.rows]
					  )

					const avgkd = (totalk / totald).toFixed(2)
		
					return <>{avgkd}</>
				  },
				accessor: row => row.m_k_deaths === 0 ? (row.m_k_kills / 1).toFixed(2) : (row.m_k_kills / row.m_k_deaths).toFixed(2),
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  {
				Header: "Archer Kills",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_a_kills + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_a_kills",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Archer Deaths",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_a_deaths + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_a_deaths",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Archer KDR",
				Footer: info => {
					// Only calculate total visits if rows change
					const totalk = React.useMemo(
					  () =>
						info.rows.reduce((sum, row) => row.values.m_a_kills + sum, 0),
					  [info.rows]
					)
					const totald = React.useMemo(
						() =>
						  info.rows.reduce((sum, row) => row.values.m_a_deaths + sum, 0),
						[info.rows]
					  )

					const avgkd = (totalk / totald).toFixed(2)
		
					return <>{avgkd}</>
				  },
				accessor: row => row.m_a_deaths === 0 ? (row.m_a_kills / 1).toFixed(2) : (row.m_a_kills / row.m_a_deaths).toFixed(2),
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  {
				Header: "Builder Kills",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_b_kills + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_b_kills",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Builder Deaths",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_b_deaths + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_b_deaths",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Builder KDR",
				Footer: info => {
					// Only calculate total visits if rows change
					const totalk = React.useMemo(
					  () =>
						info.rows.reduce((sum, row) => row.values.m_b_kills + sum, 0),
					  [info.rows]
					)
					const totald = React.useMemo(
						() =>
						  info.rows.reduce((sum, row) => row.values.m_b_deaths + sum, 0),
						[info.rows]
					  )

					const avgkd = (totalk / totald).toFixed(2)
		
					return <>{avgkd}</>
				  },
				accessor: row => row.m_b_deaths === 0 ? (row.m_b_kills / 1).toFixed(2) : (row.m_b_kills / row.m_b_deaths).toFixed(2),
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  {
				Header: "Time played",
				accessor: "m_time_played",
				Footer: "",
				Cell: ({ row }) => {
					return gametimeToMinutes(row.original.m_time_played);
				  },
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  {
				Header: "Kills / minute",
				Footer: info => {
					// Only calculate total visits if rows change
					const totalk = React.useMemo(
					  () =>
						info.rows.reduce((sum, row) => row.values.m_kills + sum, 0),
					  [info.rows]
					)

					const avg = (totalk / MAX1).toFixed(2)
		
					return <>{avg}</>
				  },
				accessor: row => (row.m_kills / (row.m_time_played / 1800)).toFixed(2),
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  {
				Header: "Deaths / minute",
				Footer: info => {
					// Only calculate total visits if rows change
					const totalk = React.useMemo(
					  () =>
						info.rows.reduce((sum, row) => row.values.m_deaths + sum, 0),
					  [info.rows]
					)

					const avg = (totalk / MAX1).toFixed(2)
		
					return <>{avg}</>
				  },
				accessor: row => (row.m_deaths / (row.m_time_played / 1800)).toFixed(2),
				maxWidth: 60,
				width: 60,
				minWidth: 60,
			  },
			  
			]
		  }
		],
		[]
	  );

	  const columns4 = useMemo(
		() => [
		  {
			Header: "Blue Leaderboard: Suicides & Teamkills",
			Footer: "",
			columns: [
			  {
				Header: '#',
				Footer: "",
				id: 'id',
				Cell: ({ row, flatRows }) => {
					if (row.original.m_username !== "TOTAL")
				  return flatRows.indexOf(row) + 1;
				},
				maxWidth: 5,
				width: 5,
				minWidth: 5,
			  },
			  {
				Header: "Username",
				Footer: "Total:",
				accessor: "m_username",
				maxWidth: 150,
				width: 150,
				minWidth: 150,
			  },
			  {
				Header: "Suicides",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_suicides + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_suicides",
				maxWidth: 80,
				width: 80,
				minWidth: 80,
			  },
			  {
				Header: "Teamkills",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_teamkills + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_teamkills",
				maxWidth: 80,
				width: 80,
				minWidth: 80,
			  },
			  
			]
		  }
		],
		[]
	  );

	  const columns5 = useMemo(
		() => [
		  {
			Header: "Red Leaderboard: Suicides & Teamkills",
			Footer: "",
			columns: [
			  {
				Header: '#',
				Footer: "",
				id: 'id',
				Cell: ({ row, flatRows }) => {
					if (row.original.m_username !== "TOTAL")
				  return flatRows.indexOf(row) + 1;
				},
				maxWidth: 5,
				width: 5,
				minWidth: 5,
			  },
			  {
				Header: "Username",
				Footer: "Total:",
				accessor: "m_username",
				maxWidth: 150,
				width: 150,
				minWidth: 150,
			  },
			  {
				Header: "Suicides",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_suicides + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_suicides",
				maxWidth: 80,
				width: 80,
				minWidth: 80,
			  },
			  {
				Header: "Teamkills",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_teamkills + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_teamkills",
				maxWidth: 80,
				width: 80,
				minWidth: 80,
			  },
			  
			]
		  }
		],
		[]
	  );

	  const columns6 = useMemo(
		() => [
		  {
			Header: "Match Leaderboard: Suicides & Teamkills",
			Footer: "",
			columns: [
			  {
				Header: '#',
				Footer: "",
				id: 'id',
				Cell: ({ row, flatRows }) => {
					if (row.original.m_username !== "TOTAL")
				  return flatRows.indexOf(row) + 1;
				},
				maxWidth: 5,
				width: 5,
				minWidth: 5,
			  },
			  {
				Header: "Username",
				Footer: "Total:",
				accessor: "m_username",
				maxWidth: 150,
				width: 150,
				minWidth: 150,
			  },
			  {
				Header: "Suicides",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_suicides + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_suicides",
				maxWidth: 80,
				width: 80,
				minWidth: 80,
			  },
			  {
				Header: "Teamkills",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.values.m_teamkills + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_teamkills",
				maxWidth: 80,
				width: 80,
				minWidth: 80,
			  },
			  
			]
		  }
		],
		[]
	  );

	  const columns7 = useMemo(
		() => [
		  {
			Header: "Blue Leaderboard: Kill Types",
			Footer: "",
			columns: [
			  {
				Header: '#',
				Footer: "",
				id: 'id',
				Cell: ({ row, flatRows }) => {
					if (row.original.m_username !== "TOTAL")
				  return flatRows.indexOf(row) + 1;
				},
				maxWidth: 50,
				width: 50,
				minWidth: 50,
			  },
			  {
				Header: "Username",
				Footer: "Total:",
				accessor: "m_username",
				maxWidth: 150,
				width: 150,
				minWidth: 150,
			  },
			  {
				Header: "Fall",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.fall + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.fall",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Drown",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.water + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.water",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Fire",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.fire + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.fire",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Stomp",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.stomp + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.stomp",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Pickaxe",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.builder + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.builder",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Spikes",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.spikes + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.spikes",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Sword",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.sword + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.sword",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Shield",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.shield + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.shield",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Bomb arrow",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.bombarrow + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.bombarrow",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Bomb",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.bomb + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.bomb",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Keg",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.keg + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.keg",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Mine",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.mine + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.mine",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Arrow",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.arrow + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.arrow",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Ballista",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.ballista + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.ballista",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Boulder",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.boulder + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.boulder",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Rocks",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.stones + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.stones",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Drill",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.drill + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.drill",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Saw",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.saw + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.saw",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  
			]
		  }
		],
		[]
	  );

	  const columns8 = useMemo(
		() => [
		  {
			Header: "Red Leaderboard: Kill Types",
			Footer: "",
			columns: [
			  {
				Header: '#',
				Footer: "",
				id: 'id',
				Cell: ({ row, flatRows }) => {
					if (row.original.m_username !== "TOTAL")
				  return flatRows.indexOf(row) + 1;
				},
				maxWidth: 50,
				width: 50,
				minWidth: 50,
			  },
			  {
				Header: "Username",
				Footer: "Total:",
				accessor: "m_username",
				maxWidth: 150,
				width: 150,
				minWidth: 150,
			  },
			  {
				Header: "Fall",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.fall + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.fall",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Drown",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.water + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.water",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Fire",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.fire + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.fire",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Stomp",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.stomp + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.stomp",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Pickaxe",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.builder + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.builder",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Spikes",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.spikes + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.spikes",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Sword",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.sword + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.sword",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Shield",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.shield + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.shield",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Bomb arrow",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.bombarrow + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.bombarrow",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Bomb",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.bomb + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.bomb",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Keg",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.keg + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.keg",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Mine",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.mine + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.mine",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Arrow",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.arrow + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.arrow",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Ballista",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.ballista + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.ballista",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Boulder",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.boulder + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.boulder",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Rocks",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.stones + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.stones",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Drill",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.drill + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.drill",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Saw",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.saw + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.saw",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  
			]
		  }
		],
		[]
	  );

	  const columns9 = useMemo(
		() => [
		  {
			Header: "Match Leaderboard: Kill Types",
			Footer: "",
			columns: [
			  {
				Header: '#',
				Footer: "",
				id: 'id',
				Cell: ({ row, flatRows }) => {
					if (row.original.m_username !== "TOTAL")
				  return flatRows.indexOf(row) + 1;
				},
				maxWidth: 50,
				width: 50,
				minWidth: 50,
			  },
			  {
				Header: "Username",
				Footer: "Total:",
				accessor: "m_username",
				maxWidth: 150,
				width: 150,
				minWidth: 150,
			  },
			  {
				Header: "Fall",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.fall + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.fall",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Drown",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.water + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.water",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Fire",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.fire + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.fire",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Stomp",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.stomp + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.stomp",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Pickaxe",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.builder + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.builder",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Spikes",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.spikes + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.spikes",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Sword",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.sword + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.sword",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Shield",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.shield + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.shield",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Bomb arrow",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.bombarrow + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.bombarrow",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Bomb",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.bomb + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.bomb",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Keg",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.keg + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.keg",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Mine",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.mine + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.mine",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Arrow",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.arrow + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.arrow",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Ballista",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.ballista + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.ballista",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Boulder",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.boulder + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.boulder",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Rocks",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.stones + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.stones",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Drill",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.drill + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.drill",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Saw",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_killhitters.saw + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: "m_killhitters.saw",
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  
			]
		  }
		],
		[]
	  );

	  const columns10 = useMemo(
		() => [
		  {
			Header: "Blue Leaderboard: Items Bought",
			Footer: "",
			columns: [
			  {
				Header: '#',
				Footer: "",
				id: 'id',
				Cell: ({ row, flatRows }) => {
					if (row.original.m_username !== "TOTAL")
				  return flatRows.indexOf(row) + 1;
				},
				maxWidth: 50,
				width: 50,
				minWidth: 50,
			  },
			  {
				Header: "Username",
				Footer: "Total:",
				accessor: "m_username",
				maxWidth: 150,
				width: 150,
				minWidth: 150,
			  },
			  {
				Header: "Bombs",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought["Bomb"] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]["Bomb"],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Water Bombs",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Water Bombs'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Water Bombs'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Mines",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought["Mine"] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]["Mine"],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Kegs",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought["Keg"] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]["Keg"],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Water Arrows",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Water Arrows'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Water Arrows'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Fire Arrows",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Fire Arrows'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Fire Arrows'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Bomb Arrows",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Bomb Arrow'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Bomb Arrow'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Water Buckets",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Water Bucket'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Water Bucket'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Boulders",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Boulder'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Boulder'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Tramps",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Trampoline'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Trampoline'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Saw",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Mill Saw'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Mill Saw'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Drill",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Drill'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Drill'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Crate",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Crate'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Crate'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Sponge",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Sponge'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Sponge'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Burger",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Burger'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Burger'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			]
		  }
		],
		[]
	  );

	  const columns11 = useMemo(
		() => [
		  {
			Header: "Red Leaderboard: Items Bought",
			Footer: "",
			columns: [
			  {
				Header: '#',
				Footer: "",
				id: 'id',
				Cell: ({ row, flatRows }) => {
					if (row.original.m_username !== "TOTAL")
				  return flatRows.indexOf(row) + 1;
				},
				maxWidth: 50,
				width: 50,
				minWidth: 50,
			  },
			  {
				Header: "Username",
				Footer: "Total:",
				accessor: "m_username",
				maxWidth: 150,
				width: 150,
				minWidth: 150,
			  },
			  {
				Header: "Bombs",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought["Bomb"] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]["Bomb"],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Water Bombs",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Water Bombs'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Water Bombs'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Mines",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought["Mine"] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]["Mine"],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Kegs",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought["Keg"] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]["Keg"],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Water Arrows",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Water Arrows'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Water Arrows'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Fire Arrows",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Fire Arrows'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Fire Arrows'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Bomb Arrows",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Bomb Arrow'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Bomb Arrow'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Water Buckets",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Water Bucket'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Water Bucket'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Boulders",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Boulder'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Boulder'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Tramps",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Trampoline'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Trampoline'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Saw",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Mill Saw'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Mill Saw'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Drill",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Drill'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Drill'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Crate",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Crate'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Crate'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Sponge",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Sponge'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Sponge'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Burger",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Burger'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Burger'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			]
		  }
		],
		[]
	  );

	  const columns12 = useMemo(
		() => [
		  {
			Header: "Match Leaderboard: Items Bought",
			Footer: "",
			columns: [
			  {
				Header: '#',
				Footer: "",
				id: 'id',
				Cell: ({ row, flatRows }) => {
					if (row.original.m_username !== "TOTAL")
				  return flatRows.indexOf(row) + 1;
				},
				maxWidth: 50,
				width: 50,
				minWidth: 50,
			  },
			  {
				Header: "Username",
				Footer: "Total:",
				accessor: "m_username",
				maxWidth: 150,
				width: 150,
				minWidth: 150,
			  },
			  {
				Header: "Bombs",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought["Bomb"] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]["Bomb"],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Water Bombs",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Water Bombs'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Water Bombs'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Mines",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought["Mine"] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]["Mine"],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Kegs",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought["Keg"] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]["Keg"],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Water Arrows",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Water Arrows'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Water Arrows'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Fire Arrows",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Fire Arrows'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Fire Arrows'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Bomb Arrows",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Bomb Arrow'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Bomb Arrow'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Water Buckets",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Water Bucket'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Water Bucket'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Boulders",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Boulder'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Boulder'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Tramps",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Trampoline'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Trampoline'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Saw",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Mill Saw'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Mill Saw'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Drill",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Drill'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Drill'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Crate",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Crate'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Crate'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Sponge",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Sponge'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Sponge'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			  {
				Header: "Burger",
				Footer: info => {
				  // Only calculate total visits if rows change
				  const total = React.useMemo(
					() =>
					  info.rows.reduce((sum, row) => row.original.m_itemsbought['Burger'] + sum, 0),
					[info.rows]
				  )
	  
				  return <>{total}</>
				},
				accessor: (data) => data["m_itemsbought"]['Burger'],
				maxWidth: 40,
				width: 40,
				minWidth: 40,
			  },
			]
		  }
		],
		[]
	  );

	if(matchInfo.length === 0) 
	{
		return (
			<>
			<Text>Please wait a few seconds for the match to load...</Text>
			</>
		)
	}

	function compare( a, b ) {
        if ( a.data < b.data){
          return 1;
        }
        if ( a.data > b.data ){
          return -1;
        }
        return 0;
      }

	const piedata_killhitters_blue = [];
	const piedata_killhitters_red = [];
	const piedata_killhitters_total = [];
	for(let step=0; step<hitterarray.length; ++step)
	{
		piedata_killhitters_blue.push({key: hitterarray[step], data: bluetotal.m_killhitters[hitterarray[step]]})
		piedata_killhitters_red.push({key: hitterarray[step], data: redtotal.m_killhitters[hitterarray[step]]})
		piedata_killhitters_total.push({key: hitterarray[step], data: blueredtotal.m_killhitters[hitterarray[step]]})
	}

	const piedata_items_blue = [];
	const piedata_items_red = [];
	const piedata_items_total = [];
	for(let step=0; step<itemarray.length; ++step)
	{
		let name = itemarray[step];
		if(itemarray[step] === "filled_bucket") name = "Water Bucket"
		piedata_items_blue.push({key: name, data: bluetotal.m_itemsbought[name]})
		piedata_items_red.push({key: name, data: redtotal.m_itemsbought[name]})
		piedata_items_total.push({key: name, data: blueredtotal.m_itemsbought[name]})
	}

	piedata_killhitters_blue.sort(compare);
	piedata_killhitters_red.sort(compare);
	piedata_killhitters_total.sort(compare);

	piedata_items_blue.sort(compare);
	piedata_items_red.sort(compare);
	piedata_items_total.sort(compare);

	let MAXMAX = parseInt(parseInt(matchInfo[matchInfo.length - 1].gametime) / (30 * 60)) + 1

	const normalLeaderboards = () => {
		
		if(showKDL)
		{
			if(showTogether)
			{
			return (
			<>
			<div className="ButtonGroup">
				<button onClick={toggleTogether}><Text style={{fontSize: 16, color: "white", fontWeight: "bold"}}>{showTogether ? "Click here to combine blue & red leaderboards" : "Click here to seperate blue & red leaderboards"}</Text></button>
			</div>
				<div className="ButtonGroup">
					<button onClick={toggleShowKDL}><Text style={{fontSize: 16, color: "white", fontWeight: "bold"}}>{"Hide leaderboard"}</Text></button>
				</div>
			<div className="Leaderboard2">
			<Table2 columns={columns} data={bluedata} />
			</div>
			<div className="Leaderboard3">
			<Table2 columns={columns2} data={reddata} />
			</div>
			</>
			)
			}
			else 
			{
				return (
					<>
				<div className="ButtonGroup">
					<button onClick={toggleTogether}><Text style={{fontSize: 16, color: "white", fontWeight: "bold"}}>{showTogether ? "Click here to combine blue & red leaderboards" : "Click here to seperate blue & red leaderboards"}</Text></button>
				</div>
				<div className="ButtonGroup">
					<button onClick={toggleShowKDL}><Text style={{fontSize: 16, color: "white", fontWeight: "bold"}}>{"Hide leaderboard"}</Text></button>
				</div>

					<div className="Leaderboard4">
					<Table3 columns={columns3} data={bluereddata} getRowProps={row => ({
				style: {
					background: row.original.m_team === 0 ? '#0C344A' : '#5F1411',
				},
				})} getCellProps={(cellInfo) => ({
					style: {
						minWidth: cellInfo.column.minWidth,
						width: cellInfo.column.width,
						borderRight: cellInfo.row.original.m_team === 0 ? '1px solid #0C344A' : '1px solid #5F1411',
						borderBottom: cellInfo.row.original.m_team === 0 ? '1px solid #66C9FF' : '1px solid #FF7C77',
						textAlign: 'left'
					}
				})}/>
					</div>
					</>
					);
			}
		}
		else
		{
			return (
				<>
				<div className="ButtonGroup">
					<button onClick={toggleTogether}><Text style={{fontSize: 16, color: "white", fontWeight: "bold"}}>{showTogether ? "Click here to combine blue & red leaderboards" : "Click here to seperate blue & red leaderboards"}</Text></button>
				</div>
					<div className="ButtonGroup">
						<button onClick={toggleShowKDL}><Text style={{fontSize: 16, color: "white", fontWeight: "bold"}}>{"Show leaderboard: general"}</Text></button>
					</div>
				</>
				)
		}
	}

	const tkSuicideLeaderboards = () => {

	if(showSTKL)
	{
		if(showTogether)
		{
		return (
		<>
			<div className="ButtonGroup">
					<button onClick={toggleShowSTKL}><Text style={{fontSize: 16, color: "white", fontWeight: "bold"}}>{"Hide leaderboard"}</Text></button>
				</div>
		<div className="Leaderboard2">
		<Table2 columns={columns4} data={bluedata} />
		</div>
		<div className="Leaderboard3">
		<Table2 columns={columns5} data={reddata} />
		</div>
		</>
		)
		}
		else 
		{
			return (
				<>
			<div className="ButtonGroup">
					<button onClick={toggleShowSTKL}><Text style={{fontSize: 16, color: "white", fontWeight: "bold"}}>{"Hide leaderboard"}</Text></button>
				</div>
				<div className="Leaderboard4">
				<Table3 columns={columns6} data={bluereddata} getRowProps={row => ({
			  style: {
				background: row.original.m_team === 0 ? '#0C344A' : '#5F1411',
			  },
			})} getCellProps={(cellInfo) => ({
				style: {
					minWidth: cellInfo.column.minWidth,
					width: cellInfo.column.width,
					borderRight: cellInfo.row.original.m_team === 0 ? '1px solid #0C344A' : '1px solid #5F1411',
					borderBottom: cellInfo.row.original.m_team === 0 ? '1px solid #66C9FF' : '1px solid #FF7C77',
					textAlign: 'left'
				}
			  })}/>
				</div>
				</>
				);
		}
	}
	else 
	{
		return (
			<>
				<div className="ButtonGroup">
					<button onClick={toggleShowSTKL}><Text style={{fontSize: 16, color: "white", fontWeight: "bold"}}>{"Show leaderboard: suicides & teamkills"}</Text></button>
				</div>
			</>
			)
	}
	}

	const hitterLeaderboards = () => {

		if(showHT)
		{
			if(showTogether)
			{
			return (
			<>
				<div className="ButtonGroup">
						<button onClick={toggleShowHT}><Text style={{fontSize: 16, color: "white", fontWeight: "bold"}}>{"Hide leaderboard"}</Text></button>
					</div>
			<div className="Leaderboard2">
			<Table2 columns={columns7} data={bluedata} />
			</div>
			<div className="Leaderboard3">
			<Table2 columns={columns8} data={reddata} />
			</div>
			<div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>
			<div style={{ display: "flex", flexDirection: "column", width: "100%", height: "500px"}}>
				<Text style={{fontSize: 32, color: "black", fontWeight: "bold", textAlign: "center"}}>Blue kill types</Text>
				<PieChart data={piedata_killhitters_blue} series={<PieArcSeries colorScheme={["#FFEC21", "#378AFF", "#FFA32F", "#f54f52", "#93f03b", "#9552ea"]} />}/>
			</div>
			<div style={{ display: "flex", flexDirection: "column", width: "100%", height: "500px"}}>
				<Text style={{fontSize: 32, color: "black", fontWeight: "bold", textAlign: "center"}}>Red kill types</Text>
				<PieChart data={piedata_killhitters_red} series={<PieArcSeries colorScheme={["#FFEC21", "#378AFF", "#FFA32F", "#f54f52", "#93f03b", "#9552ea"]} />}/>
			</div>
		</div>
		<div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", width: "100%", height: "500px"}}>
			<Text style={{fontSize: 32, color: "black", fontWeight: "bold", textAlign: "center"}}>Blue & red kill types</Text>
			<PieChart data={piedata_killhitters_total} series={<PieArcSeries colorScheme={["#FFEC21", "#378AFF", "#FFA32F", "#f54f52", "#93f03b", "#9552ea"]} />}/>
		</div>
			</>
			)
			}
			else 
			{
				return (
					<>
				<div className="ButtonGroup">
						<button onClick={toggleShowHT}><Text style={{fontSize: 16, color: "white", fontWeight: "bold"}}>{"Hide leaderboard"}</Text></button>
					</div>
					<div className="Leaderboard4">
					<Table3 columns={columns9} data={bluereddata} getRowProps={row => ({
				  style: {
					background: row.original.m_team === 0 ? '#0C344A' : '#5F1411',
				  },
				})} getCellProps={(cellInfo) => ({
					style: {
						minWidth: cellInfo.column.minWidth,
						width: cellInfo.column.width,
						borderRight: cellInfo.row.original.m_team === 0 ? '1px solid #0C344A' : '1px solid #5F1411',
						borderBottom: cellInfo.row.original.m_team === 0 ? '1px solid #66C9FF' : '1px solid #FF7C77',
						textAlign: 'left'
					}
				  })}/>
					</div>
					<div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>
			<div style={{ display: "flex", flexDirection: "column", width: "100%", height: "500px"}}>
				<Text style={{fontSize: 32, color: "black", fontWeight: "bold", textAlign: "center"}}>Blue kill types</Text>
				<PieChart data={piedata_killhitters_blue} series={<PieArcSeries colorScheme={["#FFEC21", "#378AFF", "#FFA32F", "#f54f52", "#93f03b", "#9552ea"]} />}/>
			</div>
			<div style={{ display: "flex", flexDirection: "column", width: "100%", height: "500px"}}>
				<Text style={{fontSize: 32, color: "black", fontWeight: "bold", textAlign: "center"}}>Red kill types</Text>
				<PieChart data={piedata_killhitters_red} series={<PieArcSeries colorScheme={["#FFEC21", "#378AFF", "#FFA32F", "#f54f52", "#93f03b", "#9552ea"]} />}/>
			</div>
		</div>
		<div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", width: "100%", height: "500px"}}>
			<Text style={{fontSize: 32, color: "black", fontWeight: "bold", textAlign: "center"}}>Blue & red kill types</Text>
			<PieChart data={piedata_killhitters_total} series={<PieArcSeries colorScheme={["#FFEC21", "#378AFF", "#FFA32F", "#f54f52", "#93f03b", "#9552ea"]} />}/>
		</div>
					</>
					);
			}
		}
		else 
		{
			return (
				<>
					<div className="ButtonGroup">
						<button onClick={toggleShowHT}><Text style={{fontSize: 16, color: "white", fontWeight: "bold"}}>{"Show leaderboard: kill types"}</Text></button>
					</div>
				</>
				)
		}
	}

	const itemLeaderboards = () => {

		if(showIB)
		{
			if(showTogether)
			{
			return (
			<>
				<div className="ButtonGroup">
						<button onClick={toggleShowIB}><Text style={{fontSize: 16, color: "white", fontWeight: "bold"}}>{"Hide leaderboard"}</Text></button>
					</div>
			<div className="Leaderboard2">
			<Table2 columns={columns10} data={bluedata} />
			</div>
			<div className="Leaderboard3">
			<Table2 columns={columns11} data={reddata} />
			</div>
			<div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>
			<div style={{ display: "flex", flexDirection: "column", width: "100%", height: "500px"}}>
				<Text style={{fontSize: 32, color: "black", fontWeight: "bold", textAlign: "center"}}>Items bought by Blue</Text>
				<PieChart data={piedata_items_blue} series={<PieArcSeries colorScheme={["#FFEC21", "#378AFF", "#FFA32F", "#f54f52", "#93f03b", "#9552ea"]} />}/>
			</div>
			<div style={{ display: "flex", flexDirection: "column", width: "100%", height: "500px"}}>
				<Text style={{fontSize: 32, color: "black", fontWeight: "bold", textAlign: "center"}}>Items bought by Red</Text>
				<PieChart data={piedata_items_red} series={<PieArcSeries colorScheme={["#FFEC21", "#378AFF", "#FFA32F", "#f54f52", "#93f03b", "#9552ea"]} />}/>
			</div>
		</div>
		<div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", width: "100%", height: "500px"}}>
			<Text style={{fontSize: 32, color: "black", fontWeight: "bold", textAlign: "center"}}>Items bought - total</Text>
			<PieChart data={piedata_items_total} series={<PieArcSeries colorScheme={["#FFEC21", "#378AFF", "#FFA32F", "#f54f52", "#93f03b", "#9552ea"]} />}/>
		</div>
			</>
			)
			}
			else 
			{
				return (
					<>
				<div className="ButtonGroup">
						<button onClick={toggleShowIB}><Text style={{fontSize: 16, color: "white", fontWeight: "bold"}}>{"Hide leaderboard"}</Text></button>
					</div>
					<div className="Leaderboard4">
					<Table3 columns={columns12} data={bluereddata} getRowProps={row => ({
				  style: {
					background: row.original.m_team === 0 ? '#0C344A' : '#5F1411',
				  },
				})} getCellProps={(cellInfo) => ({
					style: {
						minWidth: cellInfo.column.minWidth,
						width: cellInfo.column.width,
						borderRight: cellInfo.row.original.m_team === 0 ? '1px solid #0C344A' : '1px solid #5F1411',
						borderBottom: cellInfo.row.original.m_team === 0 ? '1px solid #66C9FF' : '1px solid #FF7C77',
						textAlign: 'left'
					}
				  })}/>
					</div>
					<div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%"}}>
			<div style={{ display: "flex", flexDirection: "column", width: "100%", height: "500px"}}>
				<Text style={{fontSize: 32, color: "black", fontWeight: "bold", textAlign: "center"}}>Items bought by Blue</Text>
				<PieChart data={piedata_items_blue} series={<PieArcSeries colorScheme={["#FFEC21", "#378AFF", "#FFA32F", "#f54f52", "#93f03b", "#9552ea"]} />}/>
			</div>
			<div style={{ display: "flex", flexDirection: "column", width: "100%", height: "500px"}}>
				<Text style={{fontSize: 32, color: "black", fontWeight: "bold", textAlign: "center"}}>Items bought by Red</Text>
				<PieChart data={piedata_items_red} series={<PieArcSeries colorScheme={["#FFEC21", "#378AFF", "#FFA32F", "#f54f52", "#93f03b", "#9552ea"]} />}/>
			</div>
		</div>
		<div style={{ display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center", width: "100%", height: "500px"}}>
			<Text style={{fontSize: 32, color: "black", fontWeight: "bold", textAlign: "center"}}>Items bought - total</Text>
			<PieChart data={piedata_items_total} series={<PieArcSeries colorScheme={["#FFEC21", "#378AFF", "#FFA32F", "#f54f52", "#93f03b", "#9552ea"]} />}/>
		</div>
					</>
					);
			}
		}
		else 
		{
			return (
				<>
					<div className="ButtonGroup">
						<button onClick={toggleShowIB}><Text style={{fontSize: 16, color: "white", fontWeight: "bold"}}>{"Show leaderboard: items bought"}</Text></button>
					</div>
				</>
				)
		}
	}

	return (
		<>
		<div className="sussy">
			<Text style={{marginRight: "auto", fontSize: 20, color: "black", textAlign: "left"}}>
				{"Use the slider below to select which time segment of the match should be used to generate the data.\n"}
				{"For example, setting the values to 4 and 15 would mean only kills (and other actions) that happened between 4th minute of the match and the 15th minute are used."}
			</Text>
			<MultiRangeSlider
				min={0}
				max={MAXMAX}
				step={1}
				ruler={false}
				label={true}
				baseClassName="amogusslider"
				preventWheel={false}
				minValue={minValue}
				maxValue={maxValue+1}
				onInput={(e) => {
					handleInput(e);
				}}
			/>
		</div>
		{ normalLeaderboards() }
		{ tkSuicideLeaderboards() }
		{ hitterLeaderboards() }
		{ itemLeaderboards() }
		</>
		);
};

export const Matches = ({ users }) => {
	return (
	  <>
		<Outlet />
	  </>
	);
};

export function GetMatch(id) {
	const [match, setMatch] = useState([]);

	// This method fetches the records from the database.
	useEffect(() => {
		async function getMatch() {
	   
		const matchInfo = await fetch(`${localhost_var}/record/GetMatchInfo/${id}`);
		const matchInfoActual = await matchInfo.json();
   
	   	setMatch(matchInfoActual);
	  }

	  getMatch();
	
	  return;
	}, [match.length]);

	return match;
}

export const Match = ({ busers }) => {
	const { userId } = useParams();

	const cock = userId;

	const matchInfo = GetMatch(cock);

	if (matchInfo.length === 0) 
	{
		return (<> Please wait for match info to load </>);
	}

	let leavedict = [];

	let bunnieplayed = false;

	for (let step=0; step<matchInfo.length; ++step)
	{
		if(matchInfo[step].action_type === "leaveteam" || matchInfo[step].action_type === "jointeam")
		{
			let name = matchInfo[step].action_type === "jointeam" ? matchInfo[step].joiner : matchInfo[step].leaver;

			if (name !== "HomekGod") continue;

			bunnieplayed = true;

			let time = matchInfo[step].gametime;

			let team = matchInfo[step].team;

			let obj = {};
			obj["type"] = matchInfo[step].action_type === "jointeam" ? "join" : "leave";
			obj["time"] = parseInt(time);
			obj["team"] = team;

			if(leavedict[name])
			{
				leavedict[name].push(obj);
			}
			else
			{
				leavedict[name] = [obj];
			}
		}
	}

	let bluetime = 0;
	let redtime = 0;

	if(bunnieplayed)
	{
		for(let stepd=0; stepd<leavedict["HomekGod"].length; stepd += 2)
		{
			if(leavedict["HomekGod"][stepd].team === "0")
			{
				let timedelta = (leavedict["HomekGod"][stepd+1].time - leavedict["HomekGod"][stepd].time);
				bluetime += timedelta;
			}
			else 
			{
				let timedelta = (leavedict["HomekGod"][stepd+1].time - leavedict["HomekGod"][stepd].time);
				redtime += timedelta;
			}
		}
	}

	let bunnieteam = bluetime > redtime ? "0" : "1";

	if (!bunnieplayed) bunnieteam = "69";

	const colorstring = matchInfo[matchInfo.length - 1].team_won === "0" ? "#0C344A" : "#5F1411"
	const bunniestring = bunnieteam === matchInfo[matchInfo.length - 1].team_won ? "green" : "red"
  
	return (
	  <>
	  <div className="container2">
		<div className="rowBuffer">
			<div className="rowElement">
			<h3 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.75rem"}}> { "Match ID" }</h3>
			<h2 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.5rem"}}> {matchInfo[0].matchid}</h2>
			</div>
			<div className="rowElement">
			<h3 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.75rem"}}> { "Date" }</h3>
			<h2 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.5rem"}}>{ timeConverter(matchInfo[0].timestamp) }</h2>
			</div>
			<div className="rowElement">
			<h3 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.75rem"}}> { "Match length" }</h3>
			<h2 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.5rem"}}>{gametimeToMinutes(parseInt(matchInfo[matchInfo.length - 1].gametime), true)}</h2>
			</div>
  		</div>
		<div className="rowBuffer">
			<div className="rowElement">
			<h3 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.75rem"}}> { "Map name" }</h3>
			<h2 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.5rem"}}> {matchInfo[matchInfo.length - 1].map_name}</h2>
			</div>
			<div className="rowElement">
			<h3 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.75rem"}}> { "Winning team" }</h3>
			<h2 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.5rem", color: colorstring}}>{matchInfo[matchInfo.length - 1].team_won === "0" ? " Blue" : " Red"} {"\n"}</h2>
			</div>
			<div className="rowElement">
			<h3 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.75rem"}}> { "Did Bunnie win?" }</h3>
			<h2 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.5rem", color: bunniestring}}>{bunnieplayed ? (bunnieteam === matchInfo[matchInfo.length - 1].team_won ? " YES" : " NO") : "Bunnie didn't play"} {"\n"}</h2>
			</div>
  		</div>
			<div className="test">
				<MatchNavbar />
				<Outlet />
			</div>
		</div>
	  </>
	);
};

export function GetMatchesUwu() {
	const [matches, setMatches] = useState([]);

	// This method fetches the records from the database.
	useEffect(() => {
		 async function getMatches() {
	   
	   const matchArray = await fetch(`${localhost_var}/record/MatchArray`);
	   const matchArrayActual = await matchArray.json();

	   const matches_start = matchArrayActual.filter(obj => {
		return (obj.action_type === "matchbegin");
	  });
	  
	   const matches_end = matchArrayActual.filter(obj => {
		return (obj.action_type === "matchend");
	  });

	   const matches_final = matches_start.concat(matches_end);

	   let poggers1 = [createArray(matches_final.length / 2)]

	   function compare2( a, b ) {
		if ( a.matchid < b.matchid){
		  return -1;
		}
		if ( a.matchid > b.matchid ){
		  return 1;
		}
		return 0;
	  }
	
		matches_final.sort(compare2);
	 
	   console.log(matches_final);
   
	   for(let step = 0; step < matches_final.length; step = step + 2)
	   {
		   poggers1[step] = matches_final[step];
		   poggers1[step].time_played = matches_final[step + 1].timestamp - matches_final[step].timestamp;
		   poggers1[step].team_won = matches_final[step + 1].team_won;
		   poggers1[step].map_name = matches_final[step + 1].map_name;
	   }
   
	   setMatches(poggers1);
   
	  }
	
	  getMatches();
	
	  return;
	}, [matches.length]);

	return matches;
}

export default function MatchHistory() {
 let matches = GetMatchesUwu();

 function compare2( a, b ) {
	if ( a.matchid < b.matchid){
	  return 1;
	}
	if ( a.matchid > b.matchid ){
	  return -1;
	}
	return 0;
  }

matches.sort(compare2);
 
 const columns = useMemo(
  () => [
    {
      Header: "Match List",
	  Footer: "",
      columns: [
        /*{
          Header: '#',
          id: 'id',
		  maxWidth: 60,
		  width: 60,
		  minWidth: 60,
          Cell: ({ row, flatRows }) => {
            return flatRows.indexOf(row) + 1;
          },
        },*/
		{
			Header: "Match No.",
			Footer: "",
			accessor: "matchid",
			Cell: ({ row }) => (<Link to={{ pathname: `/match/${row.original.matchid}` }}>{row.original.matchid}</Link>),
			maxWidth: 100,
			width: 100,
			minWidth: 100,
		},
		{
			Header: "Date started:",
			Footer: "",
			accessor: "timestamp",
			Cell: ({ row }) => {
				return timeConverter(row.original.timestamp);
			  },
			maxWidth: 200,
			width: 200,
			minWidth: 200,
		},
		{
			Header: "Match Length:",
			Footer: "",
			accessor: "time_played",
			Cell: ({ row }) => {
				return matchLength(row.original.time_played);
			  },
			maxWidth: 200,
			width: 200,
			minWidth: 200,
		},
		{
			Header: "Winning Team:",
			Footer: "",
			accessor: "team_won",
			Cell: ({ row }) => {
				return getTeam(row.original.team_won);
			  },
			maxWidth: 200,
			width: 200,
			minWidth: 200,
		},
		{
			Header: "Map Name:",
			Footer: "",
			accessor: "map_name",
			Cell: ({ row }) => {
				return row.original.map_name;
			  },
			maxWidth: 200,
			width: 200,
			minWidth: 200,
		},
        
        
      ]
    }
  ],
  []
);

	return ( 
	<>
	    <div className="Leaderboard">
      <Table23 columns={columns} data={matches} />
    </div>
	</>
	);
}
