import React, { useMemo, useState, useEffect } from "react";
import { Text } from 'react-native';
import { Link, Outlet, useParams} from 'react-router-dom';
import { PieChart, PieArcSeries } from 'reaviz';
import { Range, getTrackBackground } from "react-range";
import { Table24, Table25 } from "../components/Table";
import Media from "./media/index";

//let localhost_var = "http://localhost:5000"
let localhost_var = "https://api.kagbunnie.com"

function gametimeToMinutes(gametime, cock=false){
	let hours = parseInt(gametime / (30 * 60 * 60));
	let minutes = parseInt((gametime - hours * 30 * 60 * 60) / (30 * 60));
	let seconds = parseInt((gametime - hours * 30 * 60 * 60 - minutes * 30 * 60) / (30));

	let hours_string = hours.toString();
	let minutes_string = minutes.toString();
	let seconds_string = seconds.toString();

	if(hours_string.length === 0)
	{
		hours_string = "0";
	}

	if(minutes_string.length === 0)
	{
		minutes_string = "00";
	}
	else if(minutes_string.length === 1)
	{
		minutes_string = "0" + minutes_string;
	}

	if(seconds_string.length === 0)
	{
		seconds_string = "00";
	}
	else if(seconds_string.length === 1)
	{
		seconds_string = "0" + seconds_string;
	}

	if(!cock)
	return (hours_string + "h " + minutes_string + "m");

	else 
	return (hours_string + "h " + minutes_string + "m " + seconds_string + "s");
}

export function GetPlayer(id) {
	const [player, setPlayer] = useState([]);

	// This method fetches the records from the database.
	useEffect(() => {
		async function getPlayer() {
	   
		const playerInfo = await fetch(`${localhost_var}/record/GetPlayerInfo/${id}`);
		const playerInfoActual = await playerInfo.json();
   
	   	setPlayer(playerInfoActual);
	  }

	  getPlayer();
	
	  return;
	}, [player.length]);

	return player;
}


export const Players = ({ users }) => {
	return (
	  <>
		<Outlet />
	  </>
	);
};

export const PlayerProfile = ({ busers }) => {
	const { userId } = useParams();

  const PLAYERMOGUS = GetInfo();

  const columns1 = useMemo(
		() => [
		  {
			Header: "Top 10 most killed by",
			Footer: "",
			columns: [
			  {
				Header: '#',
				Footer: "",
				id: 'id',
				Cell: ({ row, flatRows }) => {
				  return flatRows.indexOf(row) + 1;
				},
				maxWidth: 5,
				width: 5,
				minWidth: 5,
			  },
			  {
				Header: "Username",
				Footer: "",
				accessor: "key",
				maxWidth: 150,
				width: 150,
				minWidth: 150,
			  },
			  {
				Header: "Deaths to",
				accessor: "data",
				maxWidth: 100,
				width: 100,
				minWidth: 100,
			  },
			  
			]
		  }
		],
		[]
	  );

    const columns2 = useMemo(
      () => [
        {
        Header: "Top 10 most killed players",
        Footer: "",
        columns: [
          {
          Header: '#',
          Footer: "",
          id: 'id',
          Cell: ({ row, flatRows }) => {
            return flatRows.indexOf(row) + 1;
          },
          maxWidth: 5,
          width: 5,
          minWidth: 5,
          },
          {
          Header: "Username",
          Footer: "",
          accessor: "key",
          maxWidth: 150,
          width: 150,
          minWidth: 150,
          },
          {
          Header: "Kills of",
          accessor: "data",
          maxWidth: 100,
          width: 100,
          minWidth: 100,
          },
          
        ]
        }
      ],
      []
      );

	const cock = userId;

	if (PLAYERMOGUS.length === 0) 
	{
		return (<> 
    <div className="container2">
    <Text>{ "Player: " } { userId } {"\n"}</Text>
    <Text>{ "Please wait for player info to load (this may take up to 15 seconds)" } </Text>
    </div>
    </>);
	}

  const cindex = PLAYERMOGUS.map(e => e.m_username).indexOf(userId);

  const ourplayer = PLAYERMOGUS[cindex];

  console.log(PLAYERMOGUS);

  const showBunnie = () =>
  {
    if(cock !== "HomekGod")
    {
      return (
        <>
          <div className="rowBuffer">
    <div className="rowElement" style={{width: "1050px"}}>
      <h3 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1, fontSize: "1.75rem"}}> { "Bunnie Stats" }</h3>
      <h2 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1, fontSize: "1.5rem"}}> { }</h2>
      <div className="rowInRow2">
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Matches played with Bunnie" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_matches_with_bunnie }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Matches played against Bunnie" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_matches_against_bunnie }</h4>
        </div>
      </div>
      <div className="rowInRow2">
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Bunnie kills" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_bunnie_total_kills }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Bunnie kills per match" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { (ourplayer.m_bunnie_total_kills / ourplayer.m_matches_against_bunnie).toFixed(2) }</h4>
        </div>
      </div>
      <div className="rowInRow2">
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Knight-Bunnie kills" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_bunnie_knight_kills }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Archer-Bunnie kills" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_bunnie_archer_kills }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Builder-Bunnie kills" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_bunnie_builder_kills }</h4>
        </div>
      </div>
      <div className="rowInRow2">
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Bunnie Teamkills" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_bunnie_team_kills }</h4>
        </div>
      </div>
    </div>
  </div>
        </>
      )
    }
  }

	return (
	  <>

	  <div className="container2">
    <h3 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1, fontSize: "4.0rem"}}> { userId }</h3>
    <div className="rowBuffer">
    <div className="rowElement">
      <h3 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1, fontSize: "1.75rem"}}> { "Elo" }</h3>
      <h2 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1, fontSize: "1.5rem"}}> { ourplayer.m_our_elo.toFixed(0) }</h2>
    </div>
  </div>
    <div className="rowBuffer">
    <div className="rowElement">
      <h3 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1, fontSize: "1.75rem"}}> { "Winrate" }</h3>
      <h2 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1, fontSize: "1.5rem"}}> { ( ((ourplayer.m_won_matches_arr.length / (ourplayer.m_won_matches_arr.length + ourplayer.m_lost_matches_arr.length)) * 100).toFixed(4)) + "%" }</h2>
      <div className="rowInRow">
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem", color: "#649B0D"}}> { "Matches Won" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem", color: "#649B0D"}}> { ourplayer.m_won_matches_arr.length}</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem", color: "#B73333"}}> { "Matches Lost" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem", color: "#B73333"}}> { ourplayer.m_lost_matches_arr.length }</h4>
        </div>
      </div>
      <div className="rowInRow" style={{justifyContent: "center"}}>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Matches Played" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { (ourplayer.m_won_matches_arr.length + ourplayer.m_lost_matches_arr.length) }</h4>
        </div>
      </div>
    </div>
    <div className="rowElement">
      <h3 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.75rem"}}> { "KDR" }</h3>
      <h2 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.5rem"}}> { (ourplayer.m_kills / ourplayer.m_deaths).toFixed(2) }</h2>
      <div className="rowInRow">
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Kills" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_kills }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.0rem"}}> { "Teamkills" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_teamkills }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Deaths" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_deaths }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.0rem"}}> { "Suicides" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_suicides }</h4>
        </div>
      </div>
    </div>
    <div className="rowElement">
      <h3 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.75rem"}}> { "Time played" }</h3>
      <h2 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.5rem"}}> { gametimeToMinutes(ourplayer.m_totalplayed, false) }</h2>
      <div className="rowInRow" style={{ marginRight: "20px", marginLeft: "20px"}}>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Kills per minute" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { (ourplayer.m_kills / (ourplayer.m_totalplayed / 1800)).toFixed(2) }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Deaths per minute" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { (ourplayer.m_deaths / (ourplayer.m_totalplayed / 1800)).toFixed(2) }</h4>
        </div>
      </div>
    </div>
  </div>
  <div className="rowBuffer">
    <div className="rowElement">
      <h3 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.75rem"}}> { "Knight" }</h3>
      <img src={Media.knight} alt="knight" style={{width: "32px", height: "32px", margin: "auto"}}/>
      <h2 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.5rem"}}> { (ourplayer.m_k_kills / ourplayer.m_k_deaths).toFixed(2) }</h2>
      <div className="rowInRow">
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Kills" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_k_kills }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Deaths" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_k_deaths }</h4>
        </div>
      </div>
    </div>
    <div className="rowElement">
      <h3 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.75rem"}}> { "Archer" }</h3>
      <img src={Media.archer} alt="archer" style={{width: "32px", height: "32px", margin: "auto"}}/>
      <h2 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.5rem"}}> { (ourplayer.m_a_kills / ourplayer.m_a_deaths).toFixed(2) }</h2>
      <div className="rowInRow">
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Kills" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_a_kills }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Deaths" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_a_deaths }</h4>
        </div>
      </div>
    </div>
    <div className="rowElement">
      <h3 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.75rem"}}> { "Builder" }</h3>
      <img src={Media.builder} alt="builder" style={{width: "32px", height: "32px", margin: "auto"}}/>
      <h2 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.5rem"}}> { (ourplayer.m_b_kills / ourplayer.m_b_deaths).toFixed(2) }</h2>
      <div className="rowInRow">
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Kills" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_b_kills }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Deaths" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_b_deaths }</h4>
        </div>
      </div>
    </div>
  </div>
  <div className="rowBuffer">
    <div className="rowElement" style={{width: "1050px"}}>
      <h3 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1, fontSize: "1.75rem"}}> { "Multikills" }</h3>
      <h2 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1, fontSize: "1.5rem"}}> { }</h2>
      <div className="rowInRow2">
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Triplekills" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_multikillarray.filter(x => x===3).length }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Quadrakills" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_multikillarray.filter(x => x===4).length }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Pentakills" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_multikillarray.filter(x => x===5).length }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Hexakills" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_multikillarray.filter(x => x===6).length }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Septakills" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_multikillarray.filter(x => x===7).length }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Octakills" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_multikillarray.filter(x => x===8).length }</h4>
        </div>
      </div>
    </div>
  </div>
  <div className="rowBuffer">
    <div className="rowElement" style={{width: "1050px"}}>
      <h3 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1, fontSize: "1.75rem"}}> { "Streaks" }</h3>
      <h2 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1, fontSize: "1.5rem"}}> { }</h2>
      <div className="rowInRow2">
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Highest kill streak" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_highest_streak }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Highest death streak" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_highest_dstreak }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Highest teamkill streak" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_highest_tkstreak }</h4>
        </div>
      </div>
    </div>
  </div>
  { showBunnie() }
  <div className="rowBuffer">
    <div className="rowElement" style={{width: "1050px"}}>
      <h3 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1, fontSize: "1.75rem"}}> { "Keg Stats" }</h3>
      <img src={Media.keg} alt="keg" style={{width: "32px", height: "32px", margin: "auto"}}/>
      <h2 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1, fontSize: "1.5rem"}}> { }</h2>
      <div className="rowInRow2">
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Keg kills" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_killhitters["keg"] }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Kegs bought" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_piedata_items[3]["data"] }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Kills per keg" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { (ourplayer.m_killhitters["keg"] / ourplayer.m_piedata_items[3]["data"]).toFixed(2) }</h4>
        </div>
      </div>
      <div className="rowInRow2">
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Kegs per hour" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { (ourplayer.m_piedata_items[3]["data"] / (ourplayer.m_totalplayed / 108000)).toFixed(2)  }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Kills per hour" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { (ourplayer.m_killhitters["keg"] / (ourplayer.m_totalplayed / 108000)).toFixed(2) }</h4>
        </div>
      </div>
      <div className="rowInRow2">
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Teamkills" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_kegteamkills }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Teamkills per keg" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { (ourplayer.m_kegteamkills / ourplayer.m_piedata_items[3]["data"]).toFixed(2) }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Suicides" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_kegsuicides }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Suicides per keg" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { (ourplayer.m_kegsuicides / ourplayer.m_piedata_items[3]["data"]).toFixed(2) }</h4>
        </div>
      </div>
      <div className="rowInRow2">
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "1-kill kegs" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_kegmultikillarray.filter(x => x===1).length }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "2-kill kegs" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_kegmultikillarray.filter(x => x===2).length }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "3-kill kegs" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_kegmultikillarray.filter(x => x===3).length }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "4-kill kegs" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_kegmultikillarray.filter(x => x===4).length }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "5-kill kegs" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_kegmultikillarray.filter(x => x===5).length }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "6-kill kegs" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_kegmultikillarray.filter(x => x===6).length }</h4>
        </div>
      </div>
    </div>
  </div>
  <div className="rowBuffer">
    <div className="rowElement" style={{width: "1050px"}}>
      <h3 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1, fontSize: "1.75rem"}}> { "Bomb Stats" }</h3>
      <img src={Media.bomb} alt="bomb" style={{width: "32px", height: "32px", margin: "auto"}}/>
      <h2 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1, fontSize: "1.5rem"}}> { }</h2>
      <div className="rowInRow2">
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Bomb kills" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_killhitters["bomb"] }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Bombs bought" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_piedata_items[0]["data"] }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Kills per bomb" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { (ourplayer.m_killhitters["bomb"] / ourplayer.m_piedata_items[0]["data"]).toFixed(2) }</h4>
        </div>
      </div>
      <div className="rowInRow2">
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Bombs per hour" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { (ourplayer.m_piedata_items[0]["data"] / (ourplayer.m_totalplayed / 108000)).toFixed(2)  }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Kills per hour" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { (ourplayer.m_killhitters["bomb"] / (ourplayer.m_totalplayed / 108000)).toFixed(2) }</h4>
        </div>
      </div>
      <div className="rowInRow2">
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Suicides" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_bombsuicides.length }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "Suicides per bomb" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { (ourplayer.m_bombsuicides.length / ourplayer.m_piedata_items[0]["data"]).toFixed(2) }</h4>
        </div>
      </div>
      <div className="rowInRow2">
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "1-kill bombs" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_bombmultikillarray.filter(x => x===1).length }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "2-kill bombs" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_bombmultikillarray.filter(x => x===2).length }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "3-kill bombs" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_bombmultikillarray.filter(x => x===3).length }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "4-kill bombs" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_bombmultikillarray.filter(x => x===4).length }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "5-kill bombs" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_bombmultikillarray.filter(x => x===5).length }</h4>
        </div>
        <div className="sussyColumn">
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.00rem"}}> { "6-kill bombs" }</h4>
          <h4 style={{marginBottom: ".5rem", fontWeight: 500, lineHeight: 1.2, fontSize: "1.25rem"}}> { ourplayer.m_bombmultikillarray.filter(x => x===6).length }</h4>
        </div>
      </div>
    </div>
  </div>
  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "100px", width: "100%"}}>
  <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "500px", marginLeft: "-250px", marginRight: "-250px"}}>
				<Text style={{fontSize: 32, color: "black", fontWeight: "bold", textAlign: "center"}}>Kill types</Text>
				<PieChart data={ourplayer.m_piedata_killhitters} series={<PieArcSeries colorScheme={["#FFEC21", "#378AFF", "#FFA32F", "#f54f52", "#93f03b", "#9552ea"]} />}/>
			</div>
			<div style={{ display: "flex", flexDirection: "column", width: "100%", height: "500px", marginLeft: "-250px", marginRight: "-250px"}}>
      <Text style={{fontSize: 32, color: "black", fontWeight: "bold", textAlign: "center"}}>Death types</Text>
				<PieChart data={ourplayer.m_piedata_deathhitters} series={<PieArcSeries colorScheme={["#FFEC21", "#378AFF", "#FFA32F", "#f54f52", "#93f03b", "#9552ea"]} />}/>
			</div>
	</div>
  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "100px", width: "100%"}}>
    <div style={{ display: "flex", flexDirection: "column", width: "100%", height: "500px", marginLeft: "-250px", marginRight: "-250px"}}>
				<Text style={{fontSize: 32, color: "black", fontWeight: "bold", textAlign: "center"}}>Victims of { cock } </Text>
        <div className="Leaderboard4">
        <Table25 columns={columns2} data={ourplayer.m_piedata_victims_true} />
        </div>
			</div>
			<div style={{ display: "flex", flexDirection: "column", width: "100%", height: "500px", marginLeft: "-250px", marginRight: "-250px"}}>
				<Text style={{fontSize: 32, color: "black", fontWeight: "bold", textAlign: "center"}}>Killers of { cock }</Text>
        <div className="Leaderboard4">
        <Table25 columns={columns1} data={ourplayer.m_piedata_killers_true} />
        </div>
			</div>
	</div>
  <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "100px", width: "100%"}}>
			<div style={{ display: "flex", flexDirection: "column", width: "100%", height: "500px"}}>
				<Text style={{fontSize: 32, color: "black", fontWeight: "bold", textAlign: "center"}}>Items bought</Text>
				<PieChart data={ourplayer.m_piedata_items} series={<PieArcSeries colorScheme={["#FFEC21", "#378AFF", "#FFA32F", "#f54f52", "#93f03b", "#9552ea"]} />}/>
			</div>
	</div>
  </div>
	  </>
	);
};

export function GetInfo() {
  const [infoArr, setInfoArr] = useState([]);

	// This method fetches the records from the database.
	useEffect(() => {
		async function GetInfo() {
	   
    const infoArray = await fetch(`${localhost_var}/record/InfoArray`);
    const infoArrayActual = await infoArray.json();
   
	   	setInfoArr(infoArrayActual);
	  }

	  GetInfo();
	
	  return;
	}, [infoArr.length]);

	return infoArr;
}

export function GetAll() {
  const [fullArr, setFullArr] = useState([]);

	// This method fetches the records from the database.
	useEffect(() => {
		async function GetAll() {
	   
    const fullArray = await fetch(`${localhost_var}/record/FullArray`);
    const fullArrayActual = await fullArray.json();
   
	   	setFullArr(fullArrayActual);
	  }

	  GetAll();
	
	  return;
	}, [fullArr.length]);

	return fullArr;
}

export default function Leaderboard(sussyvar=0) {

  const players = GetInfo();

  console.log(players);

  const [valubes, setValubes] = useState(1);

  const STEP = 100;
  const MIN_KILLS = 0;
  const MAX_KILLS = 2000;
  const [values, setValues] = useState([1]);
  
  const STEP2 = 5;
  const MIN_KILLS2 = 0;
  const MAX_KILLS2 = 100;
  const [values2, setValues2] = useState([5]);

  const STEP3 = 0.5;
  const MIN_KILLS3 = 0;
  const MAX_KILLS3 = 100;
  const [values3, setValues3] = useState([1]);

 let mode = sussyvar.props;

 const columns = useMemo(
  () => [
    {
      Header: "Global Leaderboard",
      Footer: "",
      columns: [
        {
          Header: '#',
          Footer: '',
          id: 'id',
          Cell: ({ row, flatRows }) => {
            return flatRows.indexOf(row) + 1;
          },
          maxWidth: 30,
          width: 30,
          minWidth: 30,
        },
        {
          Header: "Username",
          Footer: "Total:",
          accessor: "m_username",
          Cell: ({ row }) => (<Link to={{ pathname: `/player/${row.original.m_username}` }}>{row.original.m_username}</Link>),
          maxWidth: 200,
          width: 200,
          minWidth: 200,
        },
        {
          Header: "Elo",
          Footer: "",
          accessor: row => (row.m_elo).toFixed(0),
          Cell: ({ row }) => (row.original.m_elo).toFixed(0),
          maxWidth: 60,
          width: 60,
          minWidth: 60,
        },
        {
          Header: "Matches",
          Footer: "",
          accessor: row => (row.m_won_matches_arr.length + row.m_lost_matches_arr.length).toFixed(0),
          maxWidth: 60,
          width: 60,
          minWidth: 60,
        },
        {
          Header: "Wins",
          Footer: "",
          accessor: row => (row.m_won_matches_arr.length).toFixed(0),
          maxWidth: 60,
          width: 60,
          minWidth: 60,
        },
        {
          Header: "Losses",
          Footer: "",
          accessor: row => (row.m_lost_matches_arr.length).toFixed(0),
          maxWidth: 60,
          width: 60,
          minWidth: 60,
        },
        {
          Header: "Winrate",
          Footer: "",
          accessor: row => ( ((row.m_won_matches_arr.length / (row.m_won_matches_arr.length + row.m_lost_matches_arr.length)).toFixed(4) * 100)),
          maxWidth: 60,
          width: 60,
          minWidth: 60,
        },
        {
          Header: "Global Kills",
          Footer: info => {
            // Only calculate total visits if rows change
            const total = React.useMemo(
              () =>
                info.rows.reduce((sum, row) => row.values.m_kills + sum, 0),
              [info.rows]
            )

            return <>{total}</>
          },
          accessor: "m_kills",
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Global Deaths",
          Footer: info => {
            // Only calculate total visits if rows change
            const total = React.useMemo(
              () =>
                info.rows.reduce((sum, row) => row.values.m_deaths + sum, 0),
              [info.rows]
            )

            return <>{total}</>
          },
          accessor: "m_deaths",
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Global KDR",
          Footer: "",
          accessor: row => row.m_deaths === 0 ? (row.m_kills / 1).toFixed(2) : (row.m_kills / row.m_deaths).toFixed(2),
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        
      ]
    }
  ],
  []
);

const columns2 = useMemo(
  () => [
    {
      Header: "Knight Leaderboard",
      Footer: "",
      columns: [
        {
          Header: '#',
          Footer: '',
          id: 'id',
          Cell: ({ row, flatRows }) => {
            return flatRows.indexOf(row) + 1;
          },
          maxWidth: 30,
          width: 30,
          minWidth: 30,
        },
        {
          Header: "Username",
          Footer: "Total:",
          accessor: "m_username",
          Cell: ({ row }) => (<Link to={{ pathname: `/player/${row.original.m_username}` }}>{row.original.m_username}</Link>),
          maxWidth: 200,
          width: 200,
          minWidth: 200,
        },
        {
          Header: "Knight Kills",
          Footer: info => {
            // Only calculate total visits if rows change
            const total = React.useMemo(
              () =>
                info.rows.reduce((sum, row) => row.values.m_k_kills + sum, 0),
              [info.rows]
            )

            return <>{total}</>
          },
          accessor: "m_k_kills",
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Knight Deaths",
          Footer: info => {
            // Only calculate total visits if rows change
            const total = React.useMemo(
              () =>
                info.rows.reduce((sum, row) => row.values.m_k_deaths + sum, 0),
              [info.rows]
            )

            return <>{total}</>
          },
          accessor: "m_k_deaths",
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Knight KDR",
          Footer: "",
          accessor: row => row.m_k_deaths === 0 ? (row.m_k_kills / 1).toFixed(2) : (row.m_k_kills / row.m_k_deaths).toFixed(2),
          maxWidth: 60,
          width: 60,
          minWidth: 60,
        },
        
      ]
    }
  ],
  []
);

const columns3 = useMemo(
  () => [
    {
      Header: "Archer Leaderboard",
      Footer: "",
      columns: [
        {
          Header: '#',
          Footer: '',
          id: 'id',
          Cell: ({ row, flatRows }) => {
            return flatRows.indexOf(row) + 1;
          },
          maxWidth: 30,
          width: 30,
          minWidth: 30,
        },
        {
          Header: "Username",
          Footer: "Total:",
          accessor: "m_username",
          Cell: ({ row }) => (<Link to={{ pathname: `/player/${row.original.m_username}` }}>{row.original.m_username}</Link>),
          maxWidth: 200,
          width: 200,
          minWidth: 200,
        },
        {
          Header: "Archer Kills",
          Footer: info => {
            // Only calculate total visits if rows change
            const total = React.useMemo(
              () =>
                info.rows.reduce((sum, row) => row.values.m_a_kills + sum, 0),
              [info.rows]
            )

            return <>{total}</>
          },
          accessor: "m_a_kills",
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Archer Deaths",
          Footer: info => {
            // Only calculate total visits if rows change
            const total = React.useMemo(
              () =>
                info.rows.reduce((sum, row) => row.values.m_a_deaths + sum, 0),
              [info.rows]
            )

            return <>{total}</>
          },
          accessor: "m_a_deaths",
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Archer KDR",
          Footer: "",
          accessor: row => row.m_a_deaths === 0 ? (row.m_a_kills / 1).toFixed(2) : (row.m_a_kills / row.m_a_deaths).toFixed(2),
          maxWidth: 60,
          width: 60,
          minWidth: 60,
        },
        
      ]
    }
  ],
  []
);

const columns4 = useMemo(
  () => [
    {
      Header: "Builder Leaderboard",
      Footer: "",
      columns: [
        {
          Header: '#',
          Footer: '',
          id: 'id',
          Cell: ({ row, flatRows }) => {
            return flatRows.indexOf(row) + 1;
          },
          maxWidth: 30,
          width: 30,
          minWidth: 30,
        },
        {
          Header: "Username",
          Footer: "Total:",
          accessor: "m_username",
          Cell: ({ row }) => (<Link to={{ pathname: `/player/${row.original.m_username}` }}>{row.original.m_username}</Link>),
          maxWidth: 200,
          width: 200,
          minWidth: 200,
        },
        {
          Header: "Builder Kills",
          Footer: info => {
            // Only calculate total visits if rows change
            const total = React.useMemo(
              () =>
                info.rows.reduce((sum, row) => row.values.m_b_kills + sum, 0),
              [info.rows]
            )

            return <>{total}</>
          },
          accessor: "m_b_kills",
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Builder Deaths",
          Footer: info => {
            // Only calculate total visits if rows change
            const total = React.useMemo(
              () =>
                info.rows.reduce((sum, row) => row.values.m_b_deaths + sum, 0),
              [info.rows]
            )

            return <>{total}</>
          },
          accessor: "m_b_deaths",
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Builder KDR",
          Footer: "",
          accessor: row => row.m_b_deaths === 0 ? (row.m_b_kills / 1).toFixed(2) : (row.m_b_kills / row.m_b_deaths).toFixed(2),
          maxWidth: 60,
          width: 60,
          minWidth: 60,
        },
        
      ]
    }
  ],
  []
);

const columns5 = useMemo(
  () => [
    {
      Header: "Time Leaderboard",
      Footer: "",
      columns: [
        {
          Header: '#',
          Footer: '',
          id: 'id',
          Cell: ({ row, flatRows }) => {
            return flatRows.indexOf(row) + 1;
          },
          maxWidth: 30,
          width: 30,
          minWidth: 30,
        },
        {
          Header: "Username",
          Footer: "Total:",
          accessor: "m_username",
          Cell: ({ row }) => (<Link to={{ pathname: `/player/${row.original.m_username}` }}>{row.original.m_username}</Link>),
          maxWidth: 200,
          width: 200,
          minWidth: 200,
        },
        {
          Header: "Time played",
          accessor: "m_time_played",
          Footer: "",
          accessor: row => {
            return gametimeToMinutes(row.m_totalplayed);
            },
          maxWidth: 80,
          width: 80,
          minWidth: 80,
          },
          {
          Header: "Kills / minute",
          Footer: "",
          accessor: row => (row.m_kills / (row.m_totalplayed / 1800)).toFixed(2),
          maxWidth: 80,
          width: 80,
          minWidth: 80,
          },
          {
          Header: "Deaths / minute",
          Footer: "",
          accessor: row => (row.m_deaths / (row.m_totalplayed / 1800)).toFixed(2),
          maxWidth: 80,
          width: 80,
          minWidth: 80,
          },
        
      ]
    }
  ],
  []
);

const columns6 = useMemo(
  () => [
    {
      Header: "Suicides & Teamkills Leaderboard",
      Footer: "",
      columns: [
        {
          Header: '#',
          Footer: '',
          id: 'id',
          Cell: ({ row, flatRows }) => {
            return flatRows.indexOf(row) + 1;
          },
          maxWidth: 30,
          width: 30,
          minWidth: 30,
        },
        {
          Header: "Username",
          Footer: "Total:",
          accessor: "m_username",
          Cell: ({ row }) => (<Link to={{ pathname: `/player/${row.original.m_username}` }}>{row.original.m_username}</Link>),
          maxWidth: 200,
          width: 200,
          minWidth: 200,
        },
			  {
          Header: "Suicides",
          Footer: info => {
            // Only calculate total visits if rows change
            const total = React.useMemo(
            () =>
              info.rows.reduce((sum, row) => row.values.m_suicides + sum, 0),
            [info.rows]
            )
      
            return <>{total}</>
          },
          accessor: "m_suicides",
          maxWidth: 80,
          width: 80,
          minWidth: 80,
          },
          {
          Header: "Teamkills",
          Footer: info => {
            // Only calculate total visits if rows change
            const total = React.useMemo(
            () =>
              info.rows.reduce((sum, row) => row.values.m_teamkills + sum, 0),
            [info.rows]
            )
      
            return <>{total}</>
          },
          accessor: "m_teamkills",
          maxWidth: 80,
          width: 80,
          minWidth: 80,
          },
        
      ]
    }
  ],
  []
);

const columns7 = useMemo(
  () => [
    {
      Header: "Streaks Leaderboard",
      Footer: "",
      columns: [
        {
          Header: '#',
          Footer: '',
          id: 'id',
          Cell: ({ row, flatRows }) => {
            return flatRows.indexOf(row) + 1;
          },
          maxWidth: 30,
          width: 30,
          minWidth: 30,
        },
        {
          Header: "Username",
          Footer: "Total:",
          accessor: "m_username",
          Cell: ({ row }) => (<Link to={{ pathname: `/player/${row.original.m_username}` }}>{row.original.m_username}</Link>),
          maxWidth: 200,
          width: 200,
          minWidth: 200,
        },
			  {
          Header: "Highest kill streak",
          Footer: "",
          accessor: "m_highest_streak",
          maxWidth: 80,
          width: 80,
          minWidth: 80,
          },
          {
          Header: "Highest death streak",
          Footer: "",
          accessor: "m_highest_dstreak",
          maxWidth: 80,
          width: 80,
          minWidth: 80,
          },
          {
            Header: "Highest teamkill streak",
            Footer: "",
            accessor: "m_highest_tkstreak",
            maxWidth: 80,
            width: 80,
            minWidth: 80,
            },
        
      ]
    }
  ],
  []
);

const columns8 = useMemo(
  () => [
    {
      Header: "Multikill Leaderboard",
      Footer: "",
      columns: [
        {
          Header: '#',
          Footer: '',
          id: 'id',
          Cell: ({ row, flatRows }) => {
            return flatRows.indexOf(row) + 1;
          },
          maxWidth: 30,
          width: 30,
          minWidth: 30,
        },
        {
          Header: "Username",
          Footer: "Total:",
          accessor: "m_username",
          Cell: ({ row }) => (<Link to={{ pathname: `/player/${row.original.m_username}` }}>{row.original.m_username}</Link>),
          maxWidth: 200,
          width: 200,
          minWidth: 200,
        },
			  {
          Header: "Triplekills",
          Footer: "",
          accessor: row => (row.m_multikillarray.filter(x => x===3).length).toFixed(0),
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Quadrakills",
          Footer: "",
          accessor: row => (row.m_multikillarray.filter(x => x===4).length).toFixed(0),
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Pentakills",
          Footer: "",
          accessor: row => (row.m_multikillarray.filter(x => x===5).length).toFixed(0),
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Hexakills",
          Footer: "",
          accessor: row => (row.m_multikillarray.filter(x => x===6).length).toFixed(0),
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Septakills",
          Footer: "",
          accessor: row => (row.m_multikillarray.filter(x => x===7).length).toFixed(0),
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        
      ]
    }
  ],
  []
);


const columns9 = useMemo(
  () => [
    {
      Header: "Bunnie Leaderboard",
      Footer: "",
      columns: [
        {
          Header: '#',
          Footer: '',
          id: 'id',
          Cell: ({ row, flatRows }) => {
            return flatRows.indexOf(row) + 1;
          },
          maxWidth: 30,
          width: 30,
          minWidth: 30,
        },
        {
          Header: "Username",
          Footer: "Total:",
          accessor: "m_username",
          Cell: ({ row }) => (<Link to={{ pathname: `/player/${row.original.m_username}` }}>{row.original.m_username}</Link>),
          maxWidth: 200,
          width: 200,
          minWidth: 200,
        },
        {
          Header: "Matches played with Bunnie",
          Footer: "",
          accessor: row => row.m_matches_with_bunnie,
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
			  {
          Header: "Matches played against Bunnie",
          Footer: "",
          accessor: row => row.m_matches_against_bunnie,
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Percentage of Matches with Bunnie",
          Footer: "",
          accessor: row => ((row.m_matches_with_bunnie) / (row.m_matches_with_bunnie + row.m_matches_against_bunnie)).toFixed(4),
          Cell: ({ row }) => (((row.original.m_matches_with_bunnie) / (row.original.m_matches_with_bunnie + row.original.m_matches_against_bunnie)).toFixed(4) * 100).toFixed(2) + "%",
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
			  {
          Header: "Knight-Bunnie kills",
          Footer: "",
          accessor: row => row.m_bunnie_knight_kills,
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Builder-Bunnie kills",
          Footer: "",
          accessor: row => row.m_bunnie_builder_kills,
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Archer-Bunnie kills",
          Footer: "",
          accessor: row => row.m_bunnie_archer_kills,
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Bunnie teamkills",
          Footer: "",
          accessor: row => row.m_bunnie_team_kills,
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        
      ]
    }
  ],
  []
);

const columns10 = useMemo(
  () => [
    {
      Header: "Bomb Leaderboard",
      Footer: "",
      columns: [
        {
          Header: '#',
          Footer: '',
          id: 'id',
          Cell: ({ row, flatRows }) => {
            return flatRows.indexOf(row) + 1;
          },
          maxWidth: 30,
          width: 30,
          minWidth: 30,
        },
        {
          Header: "Username",
          Footer: "Total:",
          accessor: "m_username",
          Cell: ({ row }) => (<Link to={{ pathname: `/player/${row.original.m_username}` }}>{row.original.m_username}</Link>),
          maxWidth: 200,
          width: 200,
          minWidth: 200,
        },
        {
          Header: "Bombs bought",
          Footer: "",
          accessor: row => row.m_piedata_items[0]["data"],
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Bombs per hour",
          Footer: "",
          accessor: row => (row.m_piedata_items[0]["data"] / (row.m_totalplayed / 108000)).toFixed(2),
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
			  {
          Header: "Bomb kills",
          Footer: "",
          accessor: row => row.m_killhitters["bomb"],
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Kills per bomb",
          Footer: "",
          accessor: row => (row.m_killhitters["bomb"] / row.m_piedata_items[0]["data"]).toFixed(2),
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Bomb kills / knight kills",
          Footer: "",
          accessor: row => ((row.m_killhitters["bomb"] / row.m_k_kills).toFixed(4) * 100).toFixed(2) + "%",
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Bomb deaths",
          Footer: "",
          accessor: row => row.m_deathhitters["bomb"],
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Bomb deaths / total deaths",
          Footer: "",
          accessor: row => ((row.m_deathhitters["bomb"] / row.m_deaths).toFixed(4) * 100).toFixed(2) + "%",
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Bomb suicides",
          Footer: "",
          accessor: row => row.m_bombsuicides.length,
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Suicides per 100 bombs",
          Footer: "",
          accessor: row => ((row.m_bombsuicides.length / row.m_piedata_items[0]["data"]).toFixed(5) * 100).toFixed(2),
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        
        
      ]
    }
  ],
  []
);

const columns11 = useMemo(
  () => [
    {
      Header: "Keg Leaderboard",
      Footer: "",
      columns: [
        {
          Header: '#',
          Footer: '',
          id: 'id',
          Cell: ({ row, flatRows }) => {
            return flatRows.indexOf(row) + 1;
          },
          maxWidth: 30,
          width: 30,
          minWidth: 30,
        },
        {
          Header: "Username",
          Footer: "Total:",
          accessor: "m_username",
          Cell: ({ row }) => (<Link to={{ pathname: `/player/${row.original.m_username}` }}>{row.original.m_username}</Link>),
          maxWidth: 200,
          width: 200,
          minWidth: 200,
        },
        {
          Header: "Kegs bought",
          Footer: "",
          accessor: row => row.m_piedata_items[3]["data"],
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Kegs per hour",
          Footer: "",
          accessor: row => (row.m_piedata_items[3]["data"] / (row.m_totalplayed / 108000)).toFixed(2),
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
			  {
          Header: "Keg kills",
          Footer: "",
          accessor: row => row.m_killhitters["keg"],
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Kills per keg",
          Footer: "",
          accessor: row => (row.m_killhitters["keg"] / row.m_piedata_items[3]["data"]).toFixed(2),
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Keg kills / knight kills",
          Footer: "",
          accessor: row => ((row.m_killhitters["keg"] / row.m_k_kills).toFixed(4) * 100).toFixed(2) + "%",
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Keg deaths",
          Footer: "",
          accessor: row => row.m_deathhitters["keg"],
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Keg deaths / total deaths",
          Footer: "",
          accessor: row => ((row.m_deathhitters["keg"] / row.m_deaths).toFixed(4) * 100).toFixed(2) + "%",
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Keg suicides",
          Footer: "",
          accessor: row => row.m_kegsuicides,
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        {
          Header: "Keg teamkills",
          Footer: "",
          accessor: row => row.m_kegteamkills,
          maxWidth: 80,
          width: 80,
          minWidth: 80,
        },
        
        
      ]
    }
  ],
  []
);

let actual_players = [];

for(let step=0; step<players.length; ++step)
{
 if(players[step].m_kills > values[0] && (players[step].m_won_matches_arr.length + players[step].m_lost_matches_arr.length) > values2[0] && players[step].m_totalplayed > values3[0] * (30 * 60 * 60))
 {
   actual_players.push(players[step]);
 }
}

let actual_players_no_bunnie = [];

for(let step=0; step<players.length; ++step)
{
  if(players[step].m_username === "HomekGod") continue;

 if(players[step].m_kills > values[0] && (players[step].m_won_matches_arr.length + players[step].m_lost_matches_arr.length) > values2[0] && players[step].m_totalplayed > values3[0] * (30 * 60 * 60))
 {
   actual_players_no_bunnie.push(players[step]);
 }
}

const showLeaderboard = () => {

  if(valubes===1)
  {
    return (
    <>
      <div className="Leaderboard">
      <Table24 columns={columns} data={actual_players} />
      </div>
    </>
    );
  }
  if(valubes===2)
  {
    return (
    <>
      <div className="Leaderboard">
      <Table24 columns={columns2} data={actual_players} />
      </div>
    </>
    );
  }
  if(valubes===3)
  {
    return (
    <>
      <div className="Leaderboard">
      <Table24 columns={columns3} data={actual_players} />
      </div>
    </>
    );
  }
  if(valubes===4)
  {
    return (
    <>
      <div className="Leaderboard">
      <Table24 columns={columns4} data={actual_players} />
      </div>
    </>
    );
  }
  if(valubes===5)
  {
    return (
    <>
      <div className="Leaderboard">
      <Table24 columns={columns5} data={actual_players} />
      </div>
    </>
    );
  }
  if(valubes===6)
  {
    return (
    <>
      <div className="Leaderboard">
      <Table24 columns={columns6} data={actual_players} />
      </div>
    </>
    );
  }
  if(valubes===7)
  {
    return (
    <>
      <div className="Leaderboard">
      <Table24 columns={columns7} data={actual_players} />
      </div>
    </>
    );
  }
  if(valubes===8)
  {
    return (
    <>
      <div className="Leaderboard">
      <Table24 columns={columns8} data={actual_players} />
      </div>
    </>
    );
  }
  if(valubes===9)
  {
    return (
    <>
      <div className="Leaderboard">
      <Table24 columns={columns11} data={actual_players} />
      </div>
    </>
    );
  }
  if(valubes===10)
  {
    return (
    <>
      <div className="Leaderboard">
      <Table24 columns={columns10} data={actual_players} />
      </div>
    </>
    );
  }
  if(valubes===11)
  {
    return (
    <>
      <div className="Leaderboard">
      <Table24 columns={columns9} data={actual_players_no_bunnie} />
      </div>
    </>
    );
  }


}


if (mode===0)
 {
	return ( 
	<>
  		<div className="test">
        <h5> Data updates every 30 min. Some things (such as elo) may not be calculated properly during a data update.</h5>
        <div style={{height: "120px"}}>
        <h4 style={{marginBottom: "30px"}}>Minimum kill count:</h4>
        <Range
        values={values}
        step={STEP}
        min={MIN_KILLS}
        max={MAX_KILLS}
        onChange={(values) => setValues(values)}
        renderMark={({ props, index }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '16px',
              width: '5px',
              backgroundColor: index * STEP < values[0] ? '#548BF4' : '#ccc'
            }}
          />
        )}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%'
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values: values,
                  colors: ['#548BF4', '#ccc'],
                  min: MIN_KILLS,
                  max: MAX_KILLS,
                }),
                alignSelf: 'center'
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '42px',
              width: '42px',
              borderRadius: '4px',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA'
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '-28px',
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '14px',
                fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                padding: '4px',
                borderRadius: '4px',
                backgroundColor: '#548BF4'
              }}
            >
              {values[0].toFixed(1)}
            </div>
            <div
              style={{
                height: '16px',
                width: '5px',
                backgroundColor: isDragged ? '#548BF4' : '#CCC'
              }}
            />
          </div>
        )}
      />
      </div>
      <div style={{height: "120px"}}>
        <h4 style={{marginBottom: "30px"}}>Minimum matches played count:</h4>
        <Range
        values={values2}
        step={STEP2}
        min={MIN_KILLS2}
        max={MAX_KILLS2}
        onChange={(values) => setValues2(values)}
        renderMark={({ props, index }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '16px',
              width: '5px',
              backgroundColor: index * STEP2 < values2[0] ? '#548BF4' : '#ccc'
            }}
          />
        )}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%'
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values: values2,
                  colors: ['#548BF4', '#ccc'],
                  min: MIN_KILLS2,
                  max: MAX_KILLS2,
                }),
                alignSelf: 'center'
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '42px',
              width: '42px',
              borderRadius: '4px',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA'
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '-28px',
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '14px',
                fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                padding: '4px',
                borderRadius: '4px',
                backgroundColor: '#548BF4'
              }}
            >
              {values2[0].toFixed(1)}
            </div>
            <div
              style={{
                height: '16px',
                width: '5px',
                backgroundColor: isDragged ? '#548BF4' : '#CCC'
              }}
            />
          </div>
        )}
      />
      </div>
      <div style={{height: "120px"}}>
        <h4 style={{marginBottom: "30px"}}>Minimum time played (hours):</h4>
        <Range
        values={values3}
        step={STEP3}
        min={MIN_KILLS3}
        max={MAX_KILLS3}
        onChange={(values) => setValues3(values)}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: '36px',
              display: 'flex',
              width: '100%'
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: '5px',
                width: '100%',
                borderRadius: '4px',
                background: getTrackBackground({
                  values: values3,
                  colors: ['#548BF4', '#ccc'],
                  min: MIN_KILLS3,
                  max: MAX_KILLS3,
                }),
                alignSelf: 'center'
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '42px',
              width: '42px',
              borderRadius: '4px',
              backgroundColor: '#FFF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '0px 2px 6px #AAA'
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '-28px',
                color: '#fff',
                fontWeight: 'bold',
                fontSize: '14px',
                fontFamily: 'Arial,Helvetica Neue,Helvetica,sans-serif',
                padding: '4px',
                borderRadius: '4px',
                backgroundColor: '#548BF4'
              }}
            >
              {values3[0].toFixed(1)}
            </div>
            <div
              style={{
                height: '16px',
                width: '5px',
                backgroundColor: isDragged ? '#548BF4' : '#CCC'
              }}
            />
          </div>
        )}
      />
      </div>
			<div className="ButtonGroup">
				<button onClick={() => setValubes(1)}><Text style={{fontSize: 20, color: "white", fontWeight: "bold"}}>{"Global Leaderboard"}</Text></button>
        <button onClick={() => setValubes(2)}><Text style={{fontSize: 20, color: "white", fontWeight: "bold"}}>{"Knight Leaderboard"}</Text></button>
        <button onClick={() => setValubes(3)}><Text style={{fontSize: 20, color: "white", fontWeight: "bold"}}>{"Archer Leaderboard"}</Text></button>
        <button onClick={() => setValubes(4)}><Text style={{fontSize: 20, color: "white", fontWeight: "bold"}}>{"Builder Leaderboard"}</Text></button>
        <button onClick={() => setValubes(6)}><Text style={{fontSize: 20, color: "white", fontWeight: "bold"}}>{"Teamkills & Suicides Leaderboard"}</Text></button>
			</div>
      <div className="ButtonGroup">
				<button onClick={() => setValubes(7)}><Text style={{fontSize: 20, color: "white", fontWeight: "bold"}}>{"Streaks Leaderboard"}</Text></button>
        <button onClick={() => setValubes(8)}><Text style={{fontSize: 20, color: "white", fontWeight: "bold"}}>{"Multikill Leaderboard"}</Text></button>
        <button onClick={() => setValubes(9)}><Text style={{fontSize: 20, color: "white", fontWeight: "bold"}}>{"Keg Leaderboard"}</Text></button>
        <button onClick={() => setValubes(10)}><Text style={{fontSize: 20, color: "white", fontWeight: "bold"}}>{"Bomb Leaderboard"}</Text></button>
        <button onClick={() => setValubes(11)}><Text style={{fontSize: 20, color: "white", fontWeight: "bold"}}>{"Bunnie Leaderboard"}</Text></button>
        <button onClick={() => setValubes(5)}><Text style={{fontSize: 20, color: "white", fontWeight: "bold"}}>{"Time Leaderboard"}</Text></button>
			</div>
      { showLeaderboard() }
				<Outlet />
			</div>
	</>
	);
  }
}

// {rendertable ? <Table columns={columns} data={players} /> : null}
