import { Link, useMatch, useResolvedPath } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.css";

export default function Navbar() {
	return (
		<nav className="nav">
		<Link to="/" className="site-title">KAG EU Captains</Link>
		<ul>
			<CustomLink to="/leaderboard">Leaderboard</CustomLink>
			<CustomLink to="/matchhistory">Match History</CustomLink>
		</ul>
	</nav>
	)
}

export function MatchNavbar() {
	return (
		<nav className="nav2">
		<ul>
			<CustomLink to="EventHistory">Event history</CustomLink>
			<CustomLink to="Leaderboard">Scoreboards & stats</CustomLink>
		</ul>
	</nav>
	)
}

export function LeaderboardNavbar() {
	return (
		<nav className="nav3">
		<ul>
			<CustomLink to="GlobalLeaderboard">Global Leaderboard</CustomLink>
			<CustomLink to="KnightLeaderboard">Knight Leaderboard</CustomLink>
			<CustomLink to="ArcherLeaderboard">Archer Leaderboard</CustomLink>
			<CustomLink to="BuilderLeaderboard">Builder Leaderboard</CustomLink>
			<CustomLink to="TimeLeaderboard">Time Leaderboard</CustomLink>
		</ul>
	</nav>
	)
}

function CustomLink({ to, children, ...props }) {
	const resolvedPath = useResolvedPath(to)
	const isActive = useMatch({ path: resolvedPath.pathname, end: true})
	return (
	  <li className={isActive ? "active" : ""}>
	    <Link to={to} {...props}>
          {children}
		</Link>
      </li>
	)
}