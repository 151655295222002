import React from 'react';
import Navbar from './Navbar'
import Home from './pages/Home'
import Leaderboard from './pages/Leaderboard'
import MatchHistory from './pages/MatchHistory'
//import Match from './pages/MatchHistory'
import { Matches, Match, Scoreboard, EventHistory, GetMatchesUwu }  from './pages/MatchHistory'
import { Players, PlayerProfile}  from './pages/Leaderboard'
import { Route, Routes, Link, Outlet, useParams, Navigate } from 'react-router-dom'
import background from "./sussy.png";

function App() {
  return (
  <>
    <Navbar />
    <div className="container">
      <Routes>
      	<Route path="/" element={<Home />} />
      	<Route path="/leaderboard" element={<><Leaderboard props={0} /></>}>
          <Route path="" Navigate to="GlobalLeaderboard"/>
          <Route path="GlobalLeaderboard" element={<Leaderboard props={1} id={GetMatchesUwu()} />} />
          <Route path="KnightLeaderboard" element={<Leaderboard props={2} id={GetMatchesUwu()} />} />
          <Route path="ArcherLeaderboard" element={<Leaderboard props={3} id={GetMatchesUwu()} />} />
          <Route path="BuilderLeaderboard" element={<Leaderboard props={4} id={GetMatchesUwu()} />} />
          <Route path="TimeLeaderboard" element={<Leaderboard props={5} id={GetMatchesUwu()} />} />
        </Route>
        <Route path="player" element={<PlayerProfile users={GetMatchesUwu()} />}>
          <Route path=":userId" element={<Players busers={GetMatchesUwu()}/>}>
            <Route path="EventHistory" element={<EventHistory id={GetMatchesUwu()} />} />
          </Route>
        </Route>
      	<Route path="/MatchHistory" element={<MatchHistory />} />
        <Route path="match" element={<Matches users={GetMatchesUwu()} />}>
          <Route path=":userId" element={<Match busers={GetMatchesUwu()}/>}>
            <Route path="EventHistory" element={<EventHistory id={GetMatchesUwu()} />} />
            <Route path="Leaderboard" element={<Scoreboard id={GetMatchesUwu()} />} />
            <Route path="MatchStats" element={<Matches users={GetMatchesUwu()} />} />
          </Route>
        </Route>
      </Routes>
    </div>
  </>
  )
}

export default App;
