import React from "react";
import { Text } from 'react-native';
 
export default function Home() {
 return (
  <>
  <div style={{display: "flex", flexDirection: "column", textAlign: "left"}}>
  <Text style={{fontSize: 64, color: "black", fontFamily: "Arial"}} > {"Welcome to Bunnie's KAG Captains website!"}</Text>'
  <Text style={{fontSize: 24, color: "black", fontFamily: "Arial"}} > {"Data collected since late March 2022 from EU Captains matches."}</Text>
  <Text style={{fontSize: 24, color: "black", fontFamily: "Arial"}} > {}</Text>
  <Text style={{fontSize: 24, color: "black", fontFamily: "Arial"}} > {"Want an invite to captains? Ask bunnie#8553 on discord"}</Text>
  </div>
  </>
);
}
