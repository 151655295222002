const media = {
	archer: require('./archer.png'),
	assets: require('./assets.png'),
	bomb: require('./bomb.png'),
	builder: require('./builder.png'),
	keg: require('./keg.png'),
	knight: require('./knight.png'),
	suicides: require('./suicides.png')
}

export default media;